import NotificationTips from "@/pages/common/notificationTips";
import { Card, message, PageHeader } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import "./index.scss";
import SearchFilter from "./components/searchFilters";
import TableList from "./components/tableList";
import { deviceWidth } from "@/utils";
import { useCallback, useState } from "react";
import { queryReamin } from "@/api/deliveryLetterList";
import { connect } from "react-redux";

interface Props {
  permissions: any
};

const pagination = {
  current: 1,
  total: 0,
  showSizeChanger: true,
  showQuickJumper: true,
  size: deviceWidth() > 576 ? ("default" as any) : ("small" as any),
}

const OccRemainQty: React.FC<Props> = (props) => {
  const { permissions } = props;
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);
  const [totalPageNumber, setTotalPageNUmber] = useState<number>(0);
  const [remainQty, setRemainQty] = useState(null);
  const [status, setStatus] = useState<string>("");

  const cardExtra = useCallback(() => {
    return (
      <div className="hdp-uf hdp-uf-vc hdp-uf-ww card-extra">
        <div className="extra-total extra-item">
          <span>共 </span>
          <span>{totalPageNumber}</span>
          <span> 条</span>
        </div>
      </div>
    );
  }, [totalPageNumber]);

  /**
   * table翻页回调函数
   * @param page 
   */
  const onPaginationChange = (page: number) => {
    setCurrentPageNumber(page);
  };

  /**
   * 获取限量余量
   * @param params 
   */
  const getRemain = (params: any) => {
    setLoading(true);
    queryReamin(params)
      .then((res: any) => {
        if (res?.data && Object.keys(res.data).length) {
          const tableData = Array.isArray(res.data.warehouseTradeDetailList) ? res.data.warehouseTradeDetailList : [];
          const remainQty = res.data.remainQty;
          const status = res.data.status;
          setTableData(tableData);
          setRemainQty(remainQty);
          setTotalPageNUmber(tableData.length);
          setStatus(status);
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
        message.error("获取数据失败，请稍后重试。");
        setTableData([]);
        setRemainQty(null);
        setCurrentPageNumber(1);
        setTotalPageNUmber(0);
        setStatus("");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /**
   * 监听点击查询
   * @param values 
   */
  const handleSearch = useCallback((values: any) => {
    if (values.businessLine) {
      values.businessLine = [values.businessLine];
    };
    getRemain(values);
    setCurrentPageNumber(1);
    setTotalPageNUmber(0);
  }, []);

  return (
    <div className="limited-allowance">
      <PageHeader className="page-header" title="限量余量">
        <NotificationTips />
      </PageHeader>
      <div className="limited-alloance-content">
        <Card
          style={{ boxShadow: "0 5px 9px #dedbdb" }}
          bodyStyle={{ paddingTop: 0 }}
          title="查询条件"
          headStyle={{
            backgroundColor: "white",
            fontWeight: 100,
            borderBottom: "none"
          }}
        >
          <SearchFilter onSearch={handleSearch} />
        </Card>
        <Card
          style={{ boxShadow: "0 5px 9px #dedbdb", marginTop: 20, paddingTop: 16 }}
          bodyStyle={{ paddingTop: 0 }}
          headStyle={{
            backgroundColor: "white",
            borderBottom: "none",
            color: "#333",
            fontWeight: 600
          }}
        >
          <div className="card-head-title">工厂限量余量：{remainQty === 0 || remainQty ? remainQty : "-"}</div>
          <div className="status-msg">
            <span>{status}</span>
          </div>
          <div className="tips">
            <ExclamationCircleOutlined />
            <span className="label">单位：包装油单位是件，其余产品是吨</span>
          </div>
        </Card>
        {
          permissions.CNInternalUsers && (
            <Card
              style={{ boxShadow: "0 5px 9px #dedbdb", marginTop: 20 }}
              bodyStyle={{ paddingTop: 0 }}
              title="分仓限量余量"
              headStyle={{
                backgroundColor: "white",
                fontWeight: 100,
                borderBottom: "none"
              }}
              extra={cardExtra()}
            >
              <TableList
                tableData={tableData}
                pagination={{
                  ...pagination,
                  current: currentPageNumber,
                  total: totalPageNumber,
                  onChange: onPaginationChange
                }}
                loading={loading}
              />
              {
                tableData?.length === 0 &&
                <div className="tips">
                  <ExclamationCircleOutlined />
                  <span className="label">仓库货物余量不代表可以提货，因为提货仓库以合同约定为准</span>
                </div>
              }
            </Card>
          )
        }
      </div>
    </div >
  );
};

const mapStateToProps = (state: any) => ({
  permissions: state.userPermissions
});

export default connect(mapStateToProps, null)(OccRemainQty);