import { useCallback, useEffect, useState } from "react";
import { Button, Col, DatePicker, Form, Input, message, Modal, Radio, Row, Select, Spin } from "antd";
import moment from "moment";
import MyAutoComplete from "@/pages/common/autoComplete";
import { getTransportTypes, history } from "@/api/constant";
import { getContractDetailsByTypeVague, getUnilab } from "@/api/deliveryLetterList";
import { updateDos } from "@/api/deliveryOrder";
import { getContractDetailsById } from "@/api/contractReport";
import { addZeroToBatch, cutBatchNoZero, isDeliveryByShip, formatStrTrim, formatValue } from "@/utils/formatUtils";
import { getOptionsDes, isHaveThisPermission, isNorthFactory, isVehicleNum, nullTruckLoadingLimit } from "@/utils/common";
import "./index.scss";
interface Props {
  datas: any;
  getTable: any;
  isPendingEditPage?: boolean;
  isFulfillmentOrderPage?: boolean;
  isRejectedPage?: boolean;
  isPendingPage?: boolean;
  isOrdersDo?: boolean;
  isAbleEditQty?: boolean;
}

const EditText = (props: Props) => {
  const { datas, getTable, isAbleEditQty } = props;
  const [form] = Form.useForm();
  const [data, setData] = useState(datas);
  const [contractDetails, setContractDetails] = useState({} as any);
  const [visible, setVisible] = useState(false);
  const [infoShow, setInfoShow] = useState(false);
  const [errorFlag, setErrorFlag] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [isExternalDeliveryByShip, setIsExternalDeliveryByShip] = useState(false);
  const [transportTypeOption, setTransportTypeOption] = useState([{ label: "请选择", value: "", disabled: true }]); // 运输方式列表
  const [alterDeliveryList, setAlterDeliveryList] = useState([{ label: "请选择", value: "", disabled: true }]); // 嘉吉发货地址列表
  const [divisionType, setDivisionType] = useState("");
  const [batchOptions, setBatchOptions] = useState([]);
  const [unilabOptions, setUnilabOptions] = useState([]);
  const [truckMessage, setTruckMessage] = useState([]);
  const [truckLimit, setTruckLimit] = useState([]);
  const [driverMessage, setDriverMessage] = useState([]); // 承运人姓名
  const [driverNumber, setDriverNumber] = useState([]); // 承运人电话
  const [doQuantityVA, setDoQuantityVA] = useState("");
  const [values, setValues] = useState({
    contractNo: data.contractNo,
    batch: data.batch,
    cycleId: data.cycleId,
    nominationNumber: data.nominationNumber,
    unilab: data.unilab,
    doQuantity: data.doQuantity,
    doQuantityByPackage: data.doQuantityByPackage,
    truckPlateNumber: data.truckPlateNumber,
    truckLoadingLimit: data.truckLoadingLimit,
    driverName: data.driverName,
    driverContactNumber: data.driverContactNumber,
    shippingRemark: data.shippingRemark,
    shippingInstruction: data.shippingInstruction,
    emergencyReceiveAddress: data.emergencyReceiveAddress,
    transportType: data.transportType,
    shipAddress: data.shipAddress,
    bigOrder: data.bigOrder,
    distributionNo: data.distributionNo,
    transportTool: data.transportTool,
    internalMemo: data.internalMemo,
    estimatedDeliveryDate: data.estimatedDeliveryDate
  });

  const getIsExternalDeliveryByShip = () => {
    const temp = { ...values };
    if (data.buyerSellerOption === "S" && data.orgCode === "1006") {
      if (["11", "12", "22", "23"].indexOf(values.transportType) !== -1 && values.shipAddress.slice(0, 2) === "9B") {
        temp.driverName = "";
        temp.truckLoadingLimit = "";
        temp.truckPlateNumber = "";
        temp.driverContactNumber = "";
        return true;
      }
      temp.cycleId = "";
      temp.nominationNumber = "";
    } else if (data.buyerSellerOption !== undefined) {
      temp.cycleId = "";
      temp.nominationNumber = "";
    }
    setValues(temp);
    return false;
  };

  // 获取批次
  const getBatch = (value: any) => {
    setIsLoading(true);
    return getContractDetailsByTypeVague({
      contractNo: data.contractNo,
      batch: addZeroToBatch(value)
    })
      .then((res: any) => {
        if (res.code && res.code === "99999") {
          // 99999 提示后端的错误信息
          message.error(res.errorMsg);
        } else {
          const data = res.data.contract?.batch;
          const temp = data
            ? data.map((val: any) => {
              return {
                label: cutBatchNoZero(val.batch),
                value: cutBatchNoZero(val.batch)
              };
            })
            : [];
          setBatchOptions(temp);
          return temp;
        }
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // 获取unilab
  const getUnilabOptions = useCallback(() => {
    getUnilab({
      searchTerm: values.unilab,
      productCode: data.productCode,
      businessDivision: data.businessDivision || "",
      orgCode: data.orgCode,
      customerCode: data.customerCode
    })
      .then((res: any) => {
        if (res.code && res.code === "99999") {
          // 99999 提示后端的错误信息
          message.error(res.errorMsg);
        } else {
          const data = res.data.unilab;
          const temp =
            data &&
            data.map((val: any) => {
              return {
                label: val.name,
                value: val.name
              };
            });
          setUnilabOptions(temp);
        }
      })
      .catch(err => {
        console.log(err);
      });
  }, [data.businessDivision, data.customerCode, data.orgCode, data.productCode, values.unilab]);

  // 获取提货委托函申请输入
  const getHistory = (label: string, value: string, setFun: any) => {
    history({
      type: label,
      searchTerm: value
    })
      .then((res: any) => {
        if (res.code && res.code === "99999") {
          // 99999 提示后端的错误信息
          message.error(res.errorMsg);
        } else {
          const data = res.data.history.map((val: any) => {
            return {
              label: val.entryKey,
              value: val.entryKey,
              entryvalue: val.entryValue
            };
          });
          setFun(data);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  // 获取车船号
  const getTruckMessage = useCallback(() => {
    getHistory("truckMessage", values.truckPlateNumber, setTruckMessage);
  }, [values.truckPlateNumber]);

  // 获取车辆限载吨位
  const getTruckLimit = useCallback(
    (data: any) => {
      if (data.truckPlateNumber) {
        const item = truckMessage
          .map((val: any) => {
            if (data.truckPlateNumber === val.value) {
              return val.entryvalue;
            }
            return undefined;
          })
          .filter((val: any) => val !== undefined)[0];
        const truckLimitOptions = item
          ? item.map((val: any) => {
            return {
              label: val,
              value: val
            };
          })
          : [];
        setTruckLimit(truckLimitOptions);
        return truckLimitOptions;
      }
      return [];
    },
    [truckMessage]
  );

  // 获取承运人
  const getDriverMessage = useCallback(() => {
    getHistory("driverMessage", values.driverName, setDriverMessage);
  }, [values.driverName]);

  // 获取承运人电话
  const getDriverNumber = useCallback(
    (data: any) => {
      if (data.driverName) {
        const item = driverMessage
          .map((val: any) => {
            if (data.driverName === val.value) {
              return val.entryvalue;
            }
            return undefined;
          })
          .filter((val: any) => val !== undefined)[0];
        const driverNumberOptions = item
          ? item.map((value: any) => {
            return {
              label: value,
              value: value
            };
          })
          : [];
        setDriverNumber(driverNumberOptions);
        return driverNumberOptions;
      }
      return [];
    },
    [driverMessage]
  );

  // 反算doQuantity
  const backCalculationDoQuantity = useCallback(
    (value: any, doQuantity: number, packageVal: number) => {
      const doQuantityByPackage = packageVal;
      if (doQuantityByPackage) {
        const calValue: any = parseFloat((doQuantityByPackage * data.conversionFactor).toString()).toFixed(3);
        if (doQuantity !== parseFloat(calValue)) {
          form.setFieldsValue({
            doQuantity: calValue
          });
          value.doQuantity = calValue;
        }
      } else {
        form.setFieldsValue({
          doQuantity: ""
        });
        value.doQuantity = "";
      }
      return value;
    },
    [data.conversionFactor, form]
  );

  // VA业务线 && packingUnit === "PC"时，将件转换为吨，并展示出来
  const getDoQuantityVAExtra = (value: any, doData: any) => {
    let doQuantityVA = "";
    if (value) {
      if (doData?.businessDivision === "VA" && doData?.packingUnit === "PC" && doData?.conversionFactorGross) {
        const doQuantity = Number(value);
        doQuantityVA = parseFloat((doQuantity * doData.conversionFactorGross).toString()).toFixed(3);
      }
    } else {
      doQuantityVA = "";
    }
    setDoQuantityVA(doQuantityVA);
  };

  // 根据计划数量吨获取计划提货数量
  const handleDoQuantity = useCallback(
    (e: any) => {
      let value = { ...values };
      const doQuantity = parseFloat(e.target.value);
      const isQuantityFactor = data.conversionFactor && doQuantity;
      if (isQuantityFactor && data.packingUnitDesc?.trim() === "吨") {
        let packageVal: any = parseFloat((doQuantity / data.conversionFactor).toString()).toFixed(3);
        packageVal = parseInt(packageVal);
        if (values.doQuantityByPackage !== packageVal.toString()) {
          form.setFieldsValue({
            doQuantityByPackage: packageVal
          });
          value.doQuantityByPackage = packageVal;
          // 反算doQuantity的值并赋值，得到实际应该输入的吨数
          value = backCalculationDoQuantity(value, doQuantity, packageVal);
        }
      } else if (isQuantityFactor && data.packingUnitDesc?.trim() === "千克") {
        const packageVal = parseFloat(((doQuantity * 0.001) / data.conversionFactor).toString()).toFixed(3);
        if (values.doQuantityByPackage !== packageVal.toString()) {
          form.setFieldsValue({
            doQuantityByPackage: packageVal
          });
          value.doQuantityByPackage = packageVal;
        }
      }
      setValues(value);
      getDoQuantityVAExtra(doQuantity, data);
    },
    [data, form, values, backCalculationDoQuantity]
  );

  // 根据计划数量件获取
  const handleDoQuantityByPackage = useCallback(
    (e: any) => {
      let value = { ...values };
      const doQuantityByPackage = parseFloat(e.target.value);
      if (data.conversionFactor && doQuantityByPackage) {
        let doQuantity = parseFloat((doQuantityByPackage * data.conversionFactor).toString()).toFixed(4);
        if (values.doQuantity !== doQuantity) {
          form.setFieldsValue({ doQuantity: doQuantity });
          value.doQuantity = doQuantity;
        }
      } else {
        form.setFieldsValue({ doQuantity: "" });
        value.doQuantity = "";
      }
      setValues(value);
    },
    [data.conversionFactor, form, values]
  );

  const CancelEdit = useCallback(() => {
    form.resetFields();
    const temp = {
      contractNo: data.contractNo,
      batch: data.batch,
      cycleId: data.cycleId,
      nominationNumber: data.nominationNumber,
      unilab: data.unilab,
      doQuantity: data.doQuantity,
      doQuantityByPackage: data.doQuantityByPackage,
      truckPlateNumber: formatStrTrim(values.truckPlateNumber, true),
      truckLoadingLimit: data.truckLoadingLimit,
      driverName: formatStrTrim(values.driverName, true),
      driverContactNumber: data.driverContactNumber,
      shippingRemark: data.shippingRemark,
      shippingInstruction: data.shippingInstruction,
      emergencyReceiveAddress: data.emergencyReceiveAddress,
      transportType: data.transportType,
      shipAddress: data.shipAddress,
      bigOrder: data.bigOrder,
      distributionNo: data.distributionNo,
      transportTool: data.transportTool,
      internalMemo: data.internalMemo,
      estimatedDeliveryDate: data.estimatedDeliveryDate
    };
    setValues(temp);
    setVisible(false);
    setErrorFlag(false);
    setInfoShow(false);
    setDoQuantityVA("");
  }, [data, form, values.driverName, values.truckPlateNumber]);

  // 拆分校验 DoQuantity 变化
  const checkDoQuantityChange = (allValues: any, changeValue: any) => {
    let doQuantityVal = changeValue.doQuantity;
    const reg = /^([1-9]\d*\.?|0\.)\d{0,3}$/;
    if (!reg.test(doQuantityVal)) {
      doQuantityVal = doQuantityVal.replace(/[^0-9\\.]/g, "");
      const doQuantityValArr = doQuantityVal.split(".");
      if (doQuantityValArr.length > 1) {
        doQuantityVal = `${doQuantityValArr[0]}.${doQuantityValArr[1].substring(0, 3)}`;
      }
      form.setFieldsValue({
        doQuantity: doQuantityVal
      });
      return false;
    }
    const doQuantity = parseFloat(doQuantityVal);
    // 运输工具为车时, 如果提货单位为吨，数量大于40吨时需要自动判断成为大提单
    if (
      allValues.transportTool === "车" &&
      doQuantity &&
      data.packingUnitDesc &&
      data.packingUnitDesc.trim() === "吨"
    ) {
      if (doQuantity > 40) {
        form.setFieldsValue({ bigOrder: "Y" });
        allValues.bigOrder = "Y";
      } else {
        form.setFieldsValue({ bigOrder: "N" });
        allValues.bigOrder = "N";
      }
    }
    return allValues;
  };

  const checkDoQuantityByPackageChange = (changeValue: any) => {
    let packageVal = changeValue.doQuantityByPackage;
    // 箱件只能输入整数
    const reg = /^\+?[1-9]\d*$/;
    if (!reg.test(packageVal)) {
      if (packageVal.toString().length === 1) {
        packageVal = packageVal.replace(/[^1-9]/g, "");
      } else {
        packageVal = packageVal.replace(/\D/g, "");
      }
      form.setFieldsValue({
        doQuantityByPackage: packageVal
      });
      if (!packageVal && data.conversionFactor) {
        form.setFieldsValue({
          doQuantity: ""
        });
      }
      return false;
    }
    if (data.conversionFactor) {
      const doQuantityByPackage = parseFloat(packageVal);
      if (doQuantityByPackage && data.packingUnitDesc?.trim() === "吨") {
        const calValue = parseFloat((Number(doQuantityByPackage) * data.conversionFactor).toString()).toFixed(3);
        form.setFieldsValue({
          doQuantity: calValue
        });
      } else if (doQuantityByPackage && data.packingUnitDesc?.trim() === "千克") {
        const calValue = parseFloat(((Number(doQuantityByPackage) * data.conversionFactor) / 0.001).toString()).toFixed(
          3
        );
        form.setFieldsValue({
          doQuantity: calValue
        });
      } else {
        form.setFieldsValue({
          doQuantity: ""
        });
      }
    }
    return true;
  };

  const checkBasic = (allValues: any, changeValue: any) => {
    if (data.conversionFactor) {
      if (changeValue.doQuantity !== undefined) {
        form.setFieldsValue({
          doQuantityByPackage: ""
        });
      }

      if (changeValue.doQuantityByPackage !== undefined) {
        form.setFieldsValue({
          doQuantity: ""
        });
      }
    }

    if (changeValue.contractNo) {
      form.setFieldsValue({
        batch: ""
      });
      allValues.batch = "";
    }

    if (changeValue.transportTool === "火车" || changeValue.transportTool === "船") {
      allValues.bigOrder = "Y";
      form.setFieldsValue({ bigOrder: "Y" });
    } else if (changeValue.transportTool === "车") {
      if (
        (data.packingUnit === "TO" || data.packingUnit === "MT") &&
        data.businessDivision === "ACM" &&
        allValues.doQuantity > 50
      ) {
        allValues.bigOrder = "Y";
        form.setFieldsValue({ bigOrder: "Y" });
      } else {
        allValues.bigOrder = "N";
        form.setFieldsValue({ bigOrder: "N" });
      }
    }
    return allValues;
  };

  const formChange = useCallback(
    (changeValue: any, allValues: any) => {
      // basic check
      allValues = checkBasic(allValues, changeValue);
      // doQuantity
      if (changeValue.doQuantity) {
        const newValue = checkDoQuantityChange(allValues, changeValue);
        if (newValue) {
          allValues = newValue;
        } else {
          return;
        }
      }

      // doQuantityByPackage
      if (changeValue.doQuantityByPackage && !checkDoQuantityByPackageChange(changeValue)) {
        return;
      }

      // truckPlateNumber
      if (changeValue.truckPlateNumber !== undefined) {
        const options = getTruckLimit(allValues);
        if (options[0]) {
          allValues.truckLoadingLimit = options[0].value;
          form.setFieldsValue({
            truckLoadingLimit: options[0].value
          });
        }
      }

      // driverName
      if (changeValue.driverName !== undefined) {
        const options = getDriverNumber(allValues);
        if (options[0]) {
          allValues.driverContactNumber = options[0].value;
          form.setFieldsValue({
            driverContactNumber: options[0].value
          });
        }
      }

      // batch
      if (changeValue.batch) {
        getBatch(changeValue.batch);
      }

      setValues({ ...values, ...allValues });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data.conversionFactor, data.packingUnitDesc, form]
  );

  // 执行更新操作
  const doUpdateDos = useCallback(
    (tempObj: any) => {
      setIsLoading(true);
      updateDos([tempObj], { type: "editDo" })
        .then((res: any) => {
          if (res.code === "99999") {
            message.error(res.errorMsg);
            return;
          }
          getTable();
          setVisible(false);
          setDoQuantityVA("");
        })
        .catch((exception: any) => {
          console.error(`Failure: ${exception}`);
          if (exception.errorMsg) {
            message.error(exception.errorMsg);
          } else {
            message.error("抱歉，系统连接出现问题，请稍后再试。");
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [getTable]
  );

  // 针对计量单位的校验
  const packingUnitCheck = useCallback((temp: any, values: any) => {
    // 物料单位为吨或袋，需要判断是否晁错车辆限载吨位
    // 物料单位为吨，直接与车辆限载吨位比较
    if (
      (temp.packingUnit === "TO" || temp.packingUnit === "MT") &&
      parseFloat(values.doQuantity) > parseFloat(values.truckLoadingLimit)
    ) {
      return false;
    }

    // 物料单位为件，反算为吨后与车辆限载吨位比较
    if (temp.packingUnit === "BAG" && temp.conversionFactor) {
      const doQuantityByPackage = values.doQuantity;
      const calValue = parseFloat((doQuantityByPackage * temp.conversionFactor).toString()).toFixed(3);
      if (parseFloat(calValue) > parseFloat(values.truckLoadingLimit)) {
        return false;
      }
    }

    if (temp.packingUnit === "KG" && temp.conversionFactor) {
      const doQuantityMT = parseFloat(values.doQuantity) * 0.001;
      if (doQuantityMT > parseFloat(values.truckLoadingLimit)) {
        return false;
      }
    }

    if (isNorthFactory(temp.orgCode) &&
      temp.packingUnit === "PC" &&
      temp?.businessDivision === "VA" &&
      doQuantityVA &&
      parseFloat(doQuantityVA) > parseFloat(values.truckLoadingLimit)) {
      return false;
    }
    return true;
  }, [doQuantityVA]);

  const truckOverloadCheck = (tempObj: any) => {
    return !(tempObj?.doQuantity &&
      tempObj.orgCode === "1006" &&
      isVehicleNum(tempObj.truckPlateNumber) &&
      tempObj.businessDivision === "ACM" &&
      ["40", "42", "01"].includes(tempObj.divisionCode) &&
      tempObj.deliveryType === "自提" &&
      tempObj.deliveryLocationCode?.startsWith("1") &&
      parseFloat(tempObj?.doQuantity) > 33);
  };

  // 保存
  const onFinish = useCallback(() => {
    let temp = { ...data };
    temp.contractNo = values.contractNo;
    temp.batch = addZeroToBatch(values.batch);
    temp.batchNo = addZeroToBatch(values.batch);
    temp.cycleId = formatValue(values.cycleId, "");
    temp.nominationNumber = formatValue(values.nominationNumber, "");
    temp.unilab = formatValue(values.unilab, "");
    temp.doQuantity = formatValue(values.doQuantity, "");
    temp.doQuantityByPackage = formatValue(values.doQuantityByPackage, 0);
    temp.truckPlateNumber = formatValue(values.truckPlateNumber, "");
    temp.truckLoadingLimit = formatValue(values.truckLoadingLimit, "");
    temp.driverName = formatValue(values.driverName, "");
    temp.driverContactNumber = formatValue(values.driverContactNumber, "");
    temp.shippingRemark = formatValue(values.shippingRemark, "");
    temp.shippingInstruction = formatValue(values.shippingInstruction, "");
    temp.emergencyReceiveAddress = formatValue(values.emergencyReceiveAddress, "");
    temp.transportType = formatValue(values.transportType, "");
    temp.shipAddress = formatValue(values.shipAddress, "");
    temp.shipAddressDesc = getOptionsDes(alterDeliveryList, values.shipAddress);
    temp.bigOrder = formatValue(values.bigOrder, "");
    temp.distributionNo = formatValue(values.distributionNo, "");
    temp.transportTool = formatValue(values.transportTool, "");
    temp.internalMemo = formatValue(values.internalMemo, "");
    temp.haveEntry = "true";
    temp.estimatedDeliveryDate = values.estimatedDeliveryDate ? moment(values.estimatedDeliveryDate).format("YYYY-MM-DD") : "";

    if (!truckOverloadCheck(temp)) {
      message.error("车提最大提货数量不能超过33吨。");
      return;
    }

    if (!packingUnitCheck(temp, values)) {
      message.error("请注意，您输入的计划提货数量已超过车辆限载吨位。");
      return;
    }
    // 此时doQuantity的值表示件数（Sap字段对应需要），还需要赋值给 doQuantityByPackage 字段
    if (temp.businessDivision === "VA" && temp?.packingUnit === "PC") {
      temp.doQuantityByPackage = values.doQuantity;
    }

    if (!errorFlag) {
      doUpdateDos(temp);
    }
  }, [alterDeliveryList, data, doUpdateDos, errorFlag, values, packingUnitCheck]);

  const initialFormValue = (data: any) => {
    // 嘉吉收获地址没有匹配值默认选第一个
    form.setFieldsValue({
      contractNo: data.contractNo,
      batch: cutBatchNoZero(data.batch),
      cycleId: data.cycleId,
      nominationNumber: data.nominationNumber,
      unilab: data.unilab,
      doQuantity: data.doQuantity,
      doQuantityByPackage: data.doQuantityByPackage || "",
      truckPlateNumber: data.truckPlateNumber,
      truckLoadingLimit: nullTruckLoadingLimit(data) === "-" ? "" : nullTruckLoadingLimit(data),
      driverName: data.driverName,
      driverContactNumber: data.driverContactNumber,
      shippingRemark: data.shippingRemark,
      shippingInstruction: data.shippingInstruction,
      emergencyReceiveAddress: data.emergencyReceiveAddress,
      transportType: data.transportType,
      shipAddress: data.shipAddress,
      bigOrder: data.bigOrder || "N",
      distributionNo: data.distributionNo,
      transportTool: data.transportTool,
      internalMemo: data.internalMemo,
      estimatedDeliveryDate: data.estimatedDeliveryDate ? moment(data.estimatedDeliveryDate) : ""
    });
  };

  const getTransportTypeOption = (data: any) => {
    // 获取运输方式
    getTransportTypes({ businessDivision: data.businessDivision }).then((res: any) => {
      if (res.data) {
        res.data.unshift({ label: "请选择", value: "", disabled: true });
        setTransportTypeOption([...res.data]);
      } else {
        setTransportTypeOption([{ label: "请选择", value: "", disabled: true }]);
      }
    });
  };

  const doSetAlterDeliveryList = (contract: any) => {
    const List =
      typeof contract.alterDeliveryList === "undefined"
        ? []
        : contract.alterDeliveryList.map((item: any) => ({
          label: `${item.deliveryLocationCode} (${item.deliveryLocation})`,
          value: item.deliveryLocationCode,
          key: item.deliveryLocationCode,
          des: item.deliveryLocation
        }));
    List.unshift({ label: "请选择", value: "", disabled: true });
    setAlterDeliveryList([...List]);
  };

  const doSetContractDetails = (isChange: boolean, contractDetailsObj: any) => {
    let newDetails = {};
    if (isChange) {
      // 如果合同号改变，取新的值
      newDetails = {
        productCode: contractDetailsObj.productCode,
        productName: contractDetailsObj.productName,
        materialSalesText: contractDetailsObj.materialSalesText,
        buyerSellerOption: contractDetailsObj.buyerSellerOption,
        incotermsCode: contractDetailsObj.incotermsCode,
        executionEndDate: contractDetailsObj.executionEndDate,
        deliveryLocation: contractDetailsObj.deliveryLocation,
        conversionFactor: contractDetailsObj.conversionFactor,
        conversionFactorGross: contractDetailsObj.conversionFactorGross
      };
      setContractDetails(contractDetailsObj);
    } else {
      newDetails = {
        productCode: data.productCode,
        productName: data.productName,
        materialSalesText: data.materialSalesText,
        buyerSellerOption: contractDetailsObj.buyerSellerOption,
        incotermsCode: contractDetailsObj.incotermsCode,
        executionEndDate: contractDetailsObj.executionEndDate,
        deliveryLocation: contractDetailsObj.deliveryLocation,
        conversionFactor: data.conversionFactor,
        conversionFactorGross: data.conversionFactorGross
      };
      setContractDetails({ ...data, ...newDetails });
    }
    return newDetails;
  };

  const handleClick = (data: any, isChange: boolean = false) => {
    setVisible(true);
    const { contractNo, customerCode, batch } = data;
    setIsLoading(true);
    getContractDetailsById({ contractNo: contractNo, customerCode, batch })
      .then((res: any) => {
        if (res?.code === "99999") {
          // 99999 提示后端的错误信息
          message.error(res.errorMsg);
        } else if (res?.data?.contract) {
          const { batch, contractDetailInfo } = res.data.contract;
          const contractDetailsObj = contractDetailInfo && batch ? { ...contractDetailInfo, ...batch[0] } : {};
          if (contractDetailsObj && Object.keys(contractDetailsObj)?.length > 0) {
            let details = {};
            const contract = batch[0];
            // 获取嘉吉发货地址
            doSetAlterDeliveryList(contract);
            // 获取设置合同详情
            details = doSetContractDetails(isChange, contractDetailsObj);

            initialFormValue({ ...data, ...details });
            setData({ ...data, ...details });
            getDoQuantityVAExtra(data.doQuantity, { ...data, ...details });
          }
          setInfoShow(true);
        }
      })
      .catch((exception: any) => {
        setErrorFlag(true);
        setInfoShow(true);
        setAlterDeliveryList([]);
        form.setFieldsValue({
          shipAddress: ""
        });
        console.error(`Failure: ${exception}`);
        message.error("提货委托函中缺少关键必填信息，请检查并重新提交。");
      })
      .finally(() => {
        setIsLoading(false);
      });
    getTransportTypeOption(data);
  };

  // 动态变化的运输方式 Mark: rafu
  const typeOfShipping = () => {
    let value;
    if (isExternalDeliveryByShip) {
      value = [{ label: "船", value: "船" }];
    } else {
      value = [
        { abel: "车", value: "车" },
        { label: "船", value: "船" },
        { label: "火车", value: "火车" }
      ];
    }
    return value;
  };

  const onEditBtn = useCallback(() => {
    handleClick(datas);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datas]);

  const doSubmit = useCallback(() => {
    form.submit();
  }, [form]);

  const doGetTruckLimit = useCallback(() => {
    getTruckLimit(values);
  }, [getTruckLimit, values]);

  const doGetDriverNumber = useCallback(() => {
    getDriverNumber(values);
  }, [getDriverNumber, values]);

  // 限制可选日期
  const onGetEstimatedDeliveryDate = (current: moment.Moment) => {
    return current && current < moment().subtract(1, "days");
  };

  useEffect(() => {
    const temp = {
      contractNo: datas.contractNo,
      batch: datas.batch,
      cycleId: datas.cycleId,
      nominationNumber: datas.nominationNumber,
      unilab: datas.unilab,
      doQuantity: datas.doQuantity,
      doQuantityByPackage: datas.doQuantityByPackage,
      truckPlateNumber: datas.truckPlateNumber,
      truckLoadingLimit: datas.truckLoadingLimit,
      driverName: datas.driverName,
      driverContactNumber: datas.driverContactNumber,
      shippingRemark: datas.shippingRemark,
      shippingInstruction: datas.shippingInstruction,
      emergencyReceiveAddress: datas.emergencyReceiveAddress,
      transportType: datas.transportType,
      shipAddress: datas.shipAddress,
      bigOrder: datas.bigOrder,
      distributionNo: datas.distributionNo,
      transportTool: datas.transportTool,
      internalMemo: datas.internalMemo,
      estimatedDeliveryDate: datas.estimatedDeliveryDate
    };
    setValues(temp);
  }, [datas]);

  useEffect(() => {
    if (data.businessDivision) {
      setDivisionType(data.businessDivision);
    }
  }, [data]);

  useEffect(() => {
    // 用于更新编辑运输方式及收货地址时对于cycleId及船号的必填判断
    if (data) {
      const flag = getIsExternalDeliveryByShip();
      data.transportType = values.transportType;
      data.shipAddress = values.shipAddress;
      if (flag) {
        data.dscTransportType = "ACM外江码头";
      }
      setIsExternalDeliveryByShip(flag);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, values.transportType, values.shipAddress]);

  return (
    <>
      <Button type="text" onClick={onEditBtn}>
        编辑
      </Button>
      {/* 编辑modal */}
      {visible && (
        <Modal
          title="编辑"
          className="delivery-letter-list delivery-letter-list-edit-text"
          visible={visible}
          onCancel={CancelEdit}
          centered
          footer={
            <>
              <Button data-testid="cancel-btn" onClick={CancelEdit}>
                取消
              </Button>
              <Button data-testid="submit-btn" loading={loading} type="primary" onClick={doSubmit}>
                确定
              </Button>
            </>
          }
          maskClosable={false}
          destroyOnClose
          width={650}
        >
          <Spin spinning={loading}>
            <Form
              form={form}
              layout="vertical"
              preserve={false}
              autoComplete="off"
              className="edit-form"
              onFinish={onFinish}
              onValuesChange={formChange}
            >
              <Row gutter={[16, 0]}>
                <Col span={12}>
                  <Form.Item
                    label="合同号"
                    name="contractNo"
                    rules={[
                      { required: true },
                      () => ({
                        async validator(_, value) {
                          if (value !== data.contractNo) {
                            handleClick({ ...data, contractNo: value }, true);
                            if (Object.keys(contractDetails).length > 0) {
                              setInfoShow(true);
                              setErrorFlag(false);
                            } else {
                              setInfoShow(true);
                              setErrorFlag(true);
                            }
                          }
                          if (!errorFlag) {
                            return Promise.resolve();
                          }
                        }
                      })
                    ]}
                    validateTrigger="onBlur"
                  >
                    <Input data-testid="executedContractNo" disabled={!!data.entrustNo} />
                  </Form.Item>
                  {infoShow && (
                    <span
                      style={{ margin: "-10px 0 20px", display: "block", fontSize: 12 }}
                      className={errorFlag ? "edit-text-error-info" : ""}
                    >
                      {errorFlag
                        ? "请输入有效的合同号！"
                        : `${contractDetails.productName}, ${contractDetails.packageSpecificationCn}, ${contractDetails.deliveryLocation}`}
                    </span>
                  )}
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="合同行项目编号"
                    name="batch"
                    rules={[
                      { required: true },
                      () => ({
                        async validator(_, value) {
                          const batch = await getBatch(value);
                          if (batch.length > 0) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject("请输入有效合同行项目编号");
                          }
                        }
                      })
                    ]}
                    validateTrigger="onBlur"
                  >
                    <MyAutoComplete options={batchOptions} disabled={!!data.entrustNo} />
                  </Form.Item>
                </Col>
                {isHaveThisPermission("CNInternalUsers") && (
                  <Col span={12}>
                    <Form.Item label="运输工具" name="transportTool" rules={[{ required: true }]}>
                      <Select data-testid="transportTool" options={typeOfShipping()} placeholder="请选择" />
                    </Form.Item>
                  </Col>
                )}
                {isExternalDeliveryByShip && isDeliveryByShip(data) && (
                  <Col span={12}>
                    <Form.Item label="CycleId" name="cycleId" rules={[{ required: true }]}>
                      <Input placeholder="请输入" />
                    </Form.Item>
                  </Col>
                )}
                {isExternalDeliveryByShip && isDeliveryByShip(data) && (
                  <Col span={12}>
                    <Form.Item label="船号" name="nominationNumber" rules={[{ required: true }]}>
                      <Input placeholder="请输入" />
                    </Form.Item>
                  </Col>
                )}
                <Col span={12}>
                  <Form.Item label="Unilab" name="unilab">
                    <MyAutoComplete options={unilabOptions} onFocus={getUnilabOptions} />
                  </Form.Item>
                </Col>
                {isAbleEditQty && (
                  <Col span={12}>
                    <Form.Item
                      label={`计划提货数量 (${contractDetails.packingUnitDesc || "-"})`}
                      name="doQuantity"
                      rules={[{ required: true }]}
                      extra={
                        doQuantityVA && <div style={{ fontWeight: 600, fontSize: 12, color: "#000" }}>
                          毛重：{doQuantityVA}吨
                        </div>
                      }
                    >
                      <Input data-testid="doQuantity" onBlur={handleDoQuantity} />
                    </Form.Item>
                  </Col>
                )}
                {isAbleEditQty &&
                  !!data.conversionFactor &&
                  !(divisionType === "VA" && contractDetails.packingCodeDesc === "件") && (
                    <Col span={12}>
                      <Form.Item
                        label={divisionType === "VA" ? "计划提货数量（箱）" : "计划提货数量（件）"}
                        name="doQuantityByPackage"
                        rules={[{ required: true }]}
                      >
                        <Input data-testid="doQuantityByPackage" onBlur={handleDoQuantityByPackage} />
                      </Form.Item>
                    </Col>
                  )}
                {!(isExternalDeliveryByShip && isDeliveryByShip(data)) && (
                  <Col span={12}>
                    <Form.Item label="车/船号" name="truckPlateNumber" rules={[{ required: true }]}>
                      <MyAutoComplete options={truckMessage} onFocus={getTruckMessage} />
                    </Form.Item>
                  </Col>
                )}
                {!(isExternalDeliveryByShip && isDeliveryByShip(data)) && (
                  <Col span={12}>
                    <Form.Item label="车辆限载吨位" name="truckLoadingLimit" rules={[{ required: true }]}>
                      <MyAutoComplete options={truckLimit} onFocus={doGetTruckLimit} />
                    </Form.Item>
                  </Col>
                )}
                {!(isExternalDeliveryByShip && isDeliveryByShip(data)) && (
                  <Col span={12}>
                    <Form.Item label="承运人姓名" name="driverName" rules={[{ required: true }]}>
                      <MyAutoComplete options={driverMessage} onFocus={getDriverMessage} />
                    </Form.Item>
                  </Col>
                )}
                {!(isExternalDeliveryByShip && isDeliveryByShip(data)) && (
                  <Col span={12}>
                    <Form.Item
                      name="driverContactNumber"
                      label="承运人联系方式"
                      rules={[
                        { required: true },
                        { len: 11, message: "联系方式应为11位数字！", validateTrigger: "onblur" },
                        () => ({
                          validator(_, value) {
                            const reg = /^[1-9]\d{10}$/;
                            if (value && !reg.test(value)) {
                              if (value.toString().length === 1) {
                                value = value.replace(/[^1-9]/g, "");
                              } else {
                                value = value.replace(/\D/g, "");
                              }
                              if (value.length > 11) {
                                value = value.substring(0, 11);
                              }
                              form.setFieldsValue({ driverContactNumber: value });
                              return Promise.reject("联系方式应为11位数字！");
                            }
                            return Promise.resolve();
                          }
                        })
                      ]}
                    >
                      <MyAutoComplete options={driverNumber} onFocus={doGetDriverNumber} />
                    </Form.Item>
                  </Col>
                )}
                <Col span={12}>
                  <Form.Item label="提单打印信息" name="shippingRemark">
                    <Input placeholder="请输入" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="发货要求" name="shippingInstruction">
                    <Input placeholder="请输入" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="紧急/一次性买方收货地址" name="emergencyReceiveAddress">
                    <Input placeholder="请输入" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="运输方式" name="transportType" rules={[{ required: true }]}>
                    <Select
                      data-testid="transportType"
                      disabled={!!data.entrustNo}
                      options={transportTypeOption}
                      placeholder="请输入"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="嘉吉发货地址" name="shipAddress" rules={[{ required: true }]}>
                    <Select disabled={!!data.entrustNo} options={alterDeliveryList} placeholder="请输入" />
                  </Form.Item>
                </Col>
                {["ACM配送车", "VA配送车", "ACM外江码头"].indexOf(data.dscTransportType) !== -1 && (
                  <Col span={12}>
                    <Form.Item label="配送订单号" name="distributionNo">
                      <Input placeholder="请输入" />
                    </Form.Item>
                  </Col>
                )}
                <Col span={12}>
                  <Form.Item label="预计提货日期" name="estimatedDeliveryDate">
                    <DatePicker
                      style={{ width: "100%" }}
                      placeholder="请选择"
                      disabledDate={onGetEstimatedDeliveryDate} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="大提单" name="bigOrder">
                    <Radio.Group>
                      <Radio value="Y">是</Radio>
                      <Radio value="N">否</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      )}
    </>
  );
};

export default EditText;
