import { useState, useEffect, useCallback } from "react";
import { Pagination, Table } from "antd";
import moment from "moment";
import RetractButton from "../components/retractButton";
import * as type from "../../constant";
import { isMobileDevice } from "@/utils/common";
import MobileTableOperation from "@/pages/common/mobileTableOperation";
import { convertUTCDateToChinaDateBySeconds, filterColumnsData } from "@/utils";
import { cutBatchNoZero, cutHeaderZero, setShipAddressDesc } from "@/utils/formatUtils";
import RejectReason from "@/pages/common/rejectReason";
import DownloadButton from "../components/downloadButton";

interface Props {
  tableData: any;
  totalCount: number;
  fieldsDesc: any;
  getOffset: any;
  getSort: any;
  getTable: any;
  permissions: any;
  userDetails: any;
  current: number;
  loading: boolean;
  searchValues: any;
  setSortOrder: any;
  sortOrder: any;
  setSelectedRows: any;
}

const TableList = (props: Props) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (selectedRowKeys: any, rows: any) => {
    setSelectedRowKeys(selectedRowKeys);
    props.setSelectedRows(rows);
  };

  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: onSelectChange,
    fixed: true
  }
  const onGetSubmitDate = useCallback(
    (_: any, record: any) => {
      if (record.submitDate) {
        return props.permissions.CNExternalUsers
          ? moment(record.submitDate).format("YYYY-MM-DD")
          : moment(record.submitDate).format("YYYY-MM-DD HH:mm:ss");
      }
      return "-";
    },
    [props.permissions.CNExternalUsers]
  );

  const allColumns = [
    {
      title: "平台序列号",
      dataIndex: "doNumber",
      key: "doNumber",
      fixed: isMobileDevice() ? false : ("left" as "left"),
      sorter: true,
      sortOrder: props.sortOrder
    },
    {
      title: "提单头编号",
      dataIndex: "salesOrderNumber",
      key: "salesOrderNumber",
      fixed: isMobileDevice() ? false : ("left" as "left"),
      render: (_: any, record: any) => {
        return record.salesOrderNumber ? cutHeaderZero(record.salesOrderNumber) : "";
      }
    },
    {
      title: "提单行项目编号",
      dataIndex: "salesOrderItem",
      key: "salesOrderItem",
      align: "center",
      fixed: isMobileDevice() ? false : ("left" as "left"),
      render: (_: any, record: any) => {
        return record.salesOrderItem ? cutHeaderZero(record.salesOrderItem) : "";
      }
    },
    {
      title: "买方",
      dataIndex: "accountName",
      key: "accountName",
      fixed: isMobileDevice() ? false : ("left" as "left"),
      render: (_: any, record: any) => {
        return (
          <div title={record.accountName} className="ellipsis">
            {record.accountName || "-"}
          </div>
        );
      }
    },
    {
      title: "提货单提交日期",
      dataIndex: "submitDate",
      key: "submitDate",
      render: onGetSubmitDate
    },
    {
      title: "产品名称",
      dataIndex: "productName",
      key: "productName",
      render: (_: any, record: any) => {
        return props.permissions.CNInternalUsers ? record.productName : record.materialSalesText;
      }
    },
    {
      title: "合同编号",
      dataIndex: "contractNumber",
      key: "contractNumber"
    },
    {
      title: "合同行项目编号",
      dataIndex: "contractItem",
      key: "contractItem",
      render: (_: any, record: any) => {
        return cutBatchNoZero(record.contractItem);
      }
    },
    {
      title: "合同单价",
      dataIndex: "price",
      key: "price",
      render: (_: any, record: any) => {
        return record.price ? parseFloat(record.price).toFixed(2) : "-";
      }
    },
    {
      title: "结算单价",
      dataIndex: "settlementPrice",
      key: "settlementPrice",
      render: (_: any, record: any) => {
        return record.settlementPrice ? parseFloat(record.settlementPrice).toFixed(2) : "-";
      }
    },
    {
      title: "结算单价单位",
      dataIndex: "settlementPriceUnitCn",
      key: "settlementPriceUnitCn"
    },
    {
      title: "计划提货数量",
      dataIndex: "doQuantity",
      key: "doQuantity"
    },
    {
      title: "嘉吉发货地址",
      dataIndex: "shipAddressDesc",
      key: "shipAddressDesc",
      render: (_: any, record: any) => {
        return setShipAddressDesc(record);
      }
    },
    {
      title: "买方收货地址",
      dataIndex: "receiveAddressDesc",
      key: "receiveAddressDesc"
    },
    {
      title: "紧急/一次性买方收货地址",
      dataIndex: "emergencyReceiveAddress",
      key: "emergencyReceiveAddress"
    },
    {
      title: "包装规格",
      dataIndex: "packageSpecificationCn",
      key: "packageSpecification"
    },
    {
      title: "承运人联系方式",
      dataIndex: "driverPhone",
      key: "driverPhone"
    },
    {
      title: "承运人姓名",
      dataIndex: "driverName",
      key: "driverName"
    },
    {
      title: "车/船号",
      dataIndex: "truckNumber",
      key: "truckNumber"
    },
    {
      title: "cycleId",
      dataIndex: "cycleId",
      key: "cycleId"
    },
    {
      title: "船号",
      dataIndex: "nominationNumber",
      key: "nominationNumber"
    },
    {
      title: "配送订单号",
      dataIndex: "distributionNo",
      key: "distributionNo"
    },
    {
      title: "提货委托状态",
      dataIndex: "doApplicationStatus",
      key: "doApplicationStatus",
      render: (_: any, record: any) => {
        return getDoApplicationStatus(record);
      }
    },
    {
      title: "拒绝原因",
      dataIndex: "rejectReason",
      key: "rejectReason",
      render: (_: any, record: any) => {
        return record.doApplicationStatus === "已开单" || (record.doApplicationStatus === "已拒绝" && record.rejectReason === "调用 SAP 接口失败") ? "-" : <RejectReason data={record} />;
      }
    },
    {
      title: "物流状态",
      dataIndex: "deliveryStatus",
      key: "deliveryStatus",
      render: (_: any, record: any) => {
        return getDeliveryStatus(record);
      }
    },
    {
      title: "物流状态更新时间",
      dataIndex: "statusModifiedDate",
      key: "statusModifiedDate",
      render: (_: any, record: any) => {
        return record.statusModifiedDate ? moment(record.statusModifiedDate).format("YYYY-MM-DD HH:mm") : "-";
      }
    },
    {
      title: "计数单位",
      dataIndex: "packingUnitDesc",
      key: "packingUnitDesc"
    },
    {
      title: "实际提货数量",
      dataIndex: "completedDoQuantity",
      key: "completedDoQuantity",
      render: (_: any, record: any) => {
        return record.completedDoQuantity ? parseFloat(record.completedDoQuantity).toFixed(3) : "";
      }
    },
    {
      title: "客户实际收货数量（B2B）",
      dataIndex: "warehouseQty",
      key: "warehouseQty",
      render: (_: any, record: any) => {
        return record.warehouseQty ? parseFloat(record.warehouseQty).toFixed(3) : "";
      }
    },
    {
      title: "客户收货回传时间（B2B）",
      dataIndex: "receiptTime",
      key: "receiptTime",
      render: (_: any, record: any) => {
        return record.receiptTime ? mapSavedTime(record.receiptTime) : "";
      }
    },
    {
      title: "提货完成日期",
      dataIndex: "completedDate",
      key: "completedDate"
    },
    {
      title: "发/提货金额（元）",
      dataIndex: "totalPrice",
      key: "totalPrice",
      render: (_: any, record: any) => {
        return record.totalPrice ? parseFloat(record.totalPrice).toFixed(2) : "-";
      }
    },
    {
      title: "业务类型代码",
      dataIndex: "divisionCode",
      key: "divisionCode"
    },
    {
      title: "业务类型",
      dataIndex: "divisionName",
      key: "commodityCode"
    },
    {
      title: "买方自有合同号",
      dataIndex: "customerOwnContractNumber",
      key: "customerOwnContractNumber"
    },
    {
      title: "拼单请求",
      dataIndex: "doMergeStatus",
      key: "mergeStatus",
      render: (_: any, record: any) => {
        return record.doMergeStatus ? "是" : "否";
      }
    },
    {
      title: "内部拼单",
      dataIndex: "doInternalMergeStatus",
      key: "doInternalMerge",
      render: (_: any, record: any) => {
        return record.doInternalMergeStatus ? "是" : "";
      }
    },
    {
      title: "外库/其他提单",
      dataIndex: "warehouseTag",
      key: "warehouseTag",
      render: (_: any, record: any) => {
        return record.warehouseTag ? "是" : "";
      }
    },
    {
      title: "外库地点",
      dataIndex: "warehouse",
      key: "warehouse"
    },
    {
      title: "预计提货日期",
      dataIndex: "deliveryDate",
      key: "deliveryDate"
    },
    {
      title: "卖方",
      dataIndex: "orgName",
      key: "orgName"
    },
    {
      title: "预约状态",
      dataIndex: "appointmentStatusCn",
      key: "appointmentStatusCn",
      render: (_: any, record: any) => {
        return record.appointmentStatusCn || "-";
      }
    },
    {
      title: "预约时间",
      dataIndex: "appointmentTime",
      key: "appointmentTime",
      render: (_: any, record: any) => {
        return record.appointmentTime ? moment(record.appointmentTime).format("YYYY-MM-DD HH:mm") : "-";
      }
    },
    {
      title: "卡车到达时间",
      dataIndex: "openCardTime",
      key: "openCardTime",
      render: (_: any, record: any) => {
        return record.openCardTime ? moment(record.openCardTime).format("YYYY-MM-DD HH:mm:ss") : "-";
      }
    },
    {
      title: "发货要求",
      dataIndex: "shippingInstruction",
      key: "shippingInstruction"
    },
    {
      title: "备注",
      dataIndex: "externalMemo",
      key: "externalMemo",
      render: (_: any, record: any) => {
        return record.externalMemo || "-";
      }
    },
    {
      title: "提单打印信息",
      dataIndex: "shippingRemark",
      key: "shippingRemark"
    },
    {
      title: "离厂重量",
      dataIndex: "departureWeight",
      key: "departureWeight"
    },
    {
      title: "离厂件数",
      dataIndex: "departurePackages",
      key: "departurePackages"
    },
    {
      title: "嘉吉发货地址",
      dataIndex: "deliveryLocationCode",
      key: "deliveryLocationCode"
    },
    {
      title: "运输方式",
      dataIndex: "transportTypeDesc",
      key: "transportTypeDesc"
    },
    {
      title: "交/提货方式",
      dataIndex: "deliveryType",
      key: "deliveryType"
    },
    {
      title: "推送类型",
      dataIndex: "ldcType",
      key: "ldcType"
    },
    {
      title: "客户提货单号",
      dataIndex: "entrustNo",
      key: "entrustNo",
      render: (_: any, record: any) => {
        return record.entrustNo || "-";
      }
    },
    {
      title: "审单人",
      dataIndex: "approvedBy",
      key: "approvedBy",
      render: (_: any, record: any) => {
        return record.approvedBy || "-";
      }
    },
    {
      title: "审单时间",
      dataIndex: "approveTime",
      key: "approveTime",
      render: (_: any, record: any) => {
        return record.approveTime ? moment(record.approveTime).format("YYYY-MM-DD HH:mm") : "-";
      }
    },
    {
      title: "限量审核状态",
      dataIndex: "limitStatus",
      key: "limitStatus"
    },
    {
      title: "占量锁定状态",
      dataIndex: "occStatus",
      key: "occStatus"
    },
    {
      title: "延迟审单时间",
      dataIndex: "delayExamineDate",
      key: "delayExamineDate",
      render: (_: any, record: any) => {
        return record.delayExamineDate ? moment(record.delayExamineDate).format("YYYY-MM-DD HH:mm") : ""
      }
    },
    {
      title: "跨客户拼单",
      dataIndex: "customerMerge",
      key: "customerMerge",
      render: (_: any, record: any) => {
        return record.customerMerge ? "是" : "否"
      }
    },
    {
      title: "强制占量",
      dataIndex: "forceReserve",
      key: "forceReserve",
      render: (_: any, record: any) => {
        return record.forceReserve ? "是" : "否"
      }
    },
    {
      title: "运输工具",
      dataIndex: "transportTool",
      key: "transportTool"
    },
    {
      title: "自动审单",
      dataIndex: "autoExamine",
      key: "autoExamine",
      render: (_: any, record: any) => {
        return record.autoExamine ? "是" : "否"
      }
    },
    {
      title: "限量审核状态",
      dataIndex: "limitStatus",
      key: "limitStatus"
    },
    {
      title: "占量锁定状态",
      dataIndex: "occStatus",
      key: "occStatus"
    },
    {
      title: "跨客户拼单",
      dataIndex: "customerMerge",
      key: "customerMerge",
      render: (_: any, record: any) => {
        return record.customerMerge ? "是" : "否"
      }
    },
    {
      title: "非自动审单原因",
      dataIndex: "personalReason",
      key: "personalReason"
    },
  ];
  const {
    tableData,
    totalCount,
    fieldsDesc,
    permissions,
    userDetails,
    current,
    getTable,
    loading,
    searchValues
  } = props;
  const [columns, setColumns] = useState([]);

  // 下载按钮
  const returnDownloadBtn = (record: any, type: any) => {
    if (type === "qualityReport") {
      return ([2, 3, 4].includes(record.qualityDownloadFlag) && <DownloadButton data={record} type="qualityReport" />);
    } else if (type === "electronicWeighsheet") {
      return ([2, 3, 4].includes(record.poundDownloadFlag) && <DownloadButton data={record} type="electronicWeighsheet" />);
    }
  };

  // 控制撤回按钮的显示
  const enableRetractAction = useCallback(() => {
    return ((permissions.CNExternalUsers && userDetails?.groups?.length > 0) ||
      (userDetails?.groups?.length > 0 && (userDetails.groups.indexOf("DXP_DSC_Role_CN_Cargill_CM") > -1 || userDetails.groups.indexOf("DXP_DSC_Role_CN_Cargill_CE") > -1)))
  }, [permissions, userDetails]);

  // 抽取方法
  const setColumnByData = (newFieldsDesc: any) => {
    // 更新获取table可展示的字段信息
    let data: any = filterColumnsData(allColumns, newFieldsDesc);

    data = data.filter((val: any) => val);
    if (isMobileDevice()) {
      data.push({
        title: "操作",
        dataIndex: "operating",
        align: "center",
        key: "operating",
        width: 60,
        className: "fixed-left-col",
        fixed: "right" as "right",
        render: (_: any, record: any) => (
          <MobileTableOperation left>
            <>
              {returnDownloadBtn(record, "qualityReport")}
              {returnDownloadBtn(record, "electronicWeighsheet")}
              {enableRetractAction() &&
                <RetractButton
                  data={record}
                  getTable={getTable}
                  permissions={permissions}
                  searchValues={searchValues}
                />
              }
            </>
          </MobileTableOperation>
        )
      })
    } else {
      data.push({
        title: "质量报告",
        key: "qualityReport",
        align: "center",
        dataIndex: "qualityReport",
        fixed: "right" as "right",
        className: "action-item-col",
        render: (_: any, record: any) => (
          <DownloadButton data={record} type="qualityReport" />
        )
      }, {
        title: "电子磅单",
        key: "electronicWeighsheet",
        align: "center",
        dataIndex: "electronicWeighsheet",
        fixed: "right" as "right",
        className: "action-item-col",
        render: (_: any, record: any) => (
          <DownloadButton data={record} type="electronicWeighsheet" />
        )
      })
      if (enableRetractAction()) {
        data.push({
          title: "撤回提单",
          key: "delete",
          align: "center",
          dataIndex: "delete",
          fixed: "right" as "right",
          className: "action-item-col",
          render: (_: any, record: any) => (
            <RetractButton
              data={record}
              getTable={getTable}
              permissions={permissions}
              searchValues={searchValues}
            />
          )
        })
      }
    }
    setColumns(data);
  };
  useEffect(() => {
    let temp: any = [];
    fieldsDesc.forEach((item: any, index: any) => {
      if (index === 1) {
        temp.push({ value: "salesOrderNumber" });
        temp.push({ value: "salesOrderItem" });
      }
      temp.push(item);
    });
    let newFieldsDesc = temp.filter((val: any) => val.value !== "arrivalDate");
    const internalExcludeFields = ["rejectReason", "externalMemo"];
    const externalExcludeFields = ["doInternalMerge", "shippingInstruction", "entrustNo", "approvedBy", "approveTime", "autoExamine", "limitStatus", "occStatus", "customerMerge", "personalReason", "delayExamineDate", "forceReserve"];
    if (permissions.CNInternalUsers) {
      newFieldsDesc = newFieldsDesc.filter((val: any) => !internalExcludeFields.includes(val.value))
    } else {
      newFieldsDesc = newFieldsDesc.filter((val: any) => !externalExcludeFields.includes(val.value));
    }
    setColumnByData(newFieldsDesc);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldsDesc]);

  const mapSavedTime = (date: any) => {
    const chinaDateTime = convertUTCDateToChinaDateBySeconds(date);
    return `${moment(chinaDateTime.date).format("YYYY-MM-DD")} ${chinaDateTime.time}`;
  };

  const getDeliveryStatus = (record: any) => {
    if (record.deliveryStatus === "已取消" && record.doApplicationStatus === "系统审核中") {
      return "";
    } else {
      return record.deliveryStatus;
    }
  };

  // 当提单拒绝原因为“调用SAP接口失败”时，提单状态在外部客户提货单列表仍显示为“受理中”
  const getDoApplicationStatus = (record: any) => {
    const rejectReasonList = ["调用 SAP 接口失败"];
    return rejectReasonList.includes(record.rejectReason) ? "受理中" : record.doApplicationStatus
  }

  // 分页
  const onChange = (page: number, pageSize: number) => {
    props.getOffset((page - 1) * pageSize, page);
  };

  const tableSort = useCallback(
    (pagination: any, filter: any, sorter: any) => {
      let sort = {
        sortDirection: type.DO_DESC,
        sort: type.DO_SORT_BY_ID_DESC
      };
      if (sorter.order === "descend") {
        sort.sort = type.DO_SORT_BY_ID_DESC;
        sort.sortDirection = type.DO_SORT_BY_ID_ASC;
        props.setSortOrder("descend");
      } else if (sorter.order === "ascend") {
        sort.sort = type.DO_SORT_BY_ID_ASC;
        sort.sortDirection = type.DO_SORT_BY_ID_DESC;
        props.setSortOrder("ascend");
      } else {
        sort.sortDirection = type.DO_SORT_BY_ID_DESC;
        sort.sort = type.DO_DESC;
        props.setSortOrder(false);
      }
      props.getSort(sort);
    },
    [props]
  );

  const onGetRowKey = useCallback((record: any) => {
    return `${record.doNumber}-${record.cdosId}`;
  }, []);

  return (
    <div className="table-list">
      <Table
        size="small"
        columns={columns}
        loading={loading}
        pagination={false}
        rowSelection={rowSelection}
        rowKey={onGetRowKey}
        dataSource={tableData}
        scroll={{ x: "max-content" }}
        onChange={tableSort}
      />
      <Pagination
        current={current}
        style={{ marginTop: 20, float: "right" }}
        showQuickJumper
        size={isMobileDevice() ? "small" : "default"}
        showSizeChanger={false}
        total={totalCount}
        onChange={onChange as any}
      />
    </div>
  );
};

export default TableList;
