import { connect } from "react-redux";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import { MenuOutlined } from "@ant-design/icons";
import { mergeDo } from "@/api/constant";
import { cutBatchNoZero } from "@/utils";
import { Button, Checkbox, message, Modal, Table } from "antd";
import { useState, useEffect, useCallback } from "react";
import "./index.scss";
import { isNorthFactory, isSouthFactory, isVehicleNum } from "@/utils/common";

interface Props {
  data?: any;
  style?: any;
  getTable?: any;
  isDeliveryLetterList?: boolean;
  isPremadeletter?: boolean;
  text?: boolean;
  userPermissions: any; // 权限数据
}

const DragHandle = SortableHandle(() => (
  <div data-testid="sort-tooltip" className="sort-tooltip" title="移动当前图标可调整拼单编号">
    <MenuOutlined style={{ cursor: "grab", color: "#999" }} />
  </div>
));

const SortableItem = SortableElement((props: any) => <tr {...props} />);
const SortableContainers = SortableContainer((props: any) => <tbody {...props} />);

const CombineButton = (props: Props) => {
  const { style, data, isDeliveryLetterList, isPremadeletter, text } = props;
  const [visible, setVisible] = useState(false);
  const [flag, setFlag] = useState(0); // 0 未拼单， 1 已拼单
  const [loading, setLoading] = useState(false);
  const [mergeSortVisible, setMergeSortVisible] = useState(false);
  const [showTransportModal, setShowTransportModal] = useState(false);
  const [showBusinessLineModal, setShowBusinessLineModal] = useState(false);
  const [checked, setChecked] = useState(false);
  const [columns, setColumns] = useState([]);
  const [dataSource, setDataSource] = useState(data);

  useEffect(() => {
    const columns: any = [
      {
        title: '',
        dataIndex: 'sort',
        width: 30,
        className: 'drag-visible',
        render: () => <DragHandle />,
      },
      {
        title: "拼单顺序",
        dataIndex: "sortOrder",
        key: "sortOrder"
      },
      {
        title: "平台序列号",
        dataIndex: "doId",
        key: "doId"
      },
      {
        title: "合同号",
        dataIndex: "contractNo",
        key: "contractNo"
      },
      {
        title: "合同行项目编号",
        dataIndex: "batch",
        key: "batch",
        render: (_: any, record: any) => {
          return cutBatchNoZero(record.batch);
        }
      },
      {
        title: "计划提货数量",
        dataIndex: "doQuantity",
        key: "doQuantity"
      },
      {
        title: "车/船号",
        dataIndex: "truckPlateNumber",
        key: "truckPlateNumber"
      },
      {
        title: "产品名称",
        dataIndex: "productName",
        key: "productName",
        render: (_: any, record: any) => {
          return props.userPermissions.CNInternalUsers ? record.productName : record.materialSalesText;
        }
      },
      {
        title: "包装规格",
        dataIndex: "packageSpecificationCn",
        key: "packageSpecification"
      }
    ];
    setColumns(columns);
  }, [props.userPermissions.CNInternalUsers]);

  useEffect(() => {
    if (data.length > 0) {
      if (isDeliveryLetterList) {
        const temp = data.filter((val: any) => val.doInternalMerge === true || val.doMerge === true);
        setFlag(temp.length > 0 ? 1 : 0);
      } else {
        const temp = data.filter((val: any) => val.doMerge === true);
        setFlag(temp.length > 0 ? 1 : 0);
      }
      const tempData = data.map((val: any, index: number) => {
        let valTemp = val;
        if (isPremadeletter || isDeliveryLetterList) {
          valTemp.index = index;
        }
        valTemp.sortOrder = index + 1;
        return valTemp;
      });
      setDataSource(tempData);
      // setChecked(data.length === 1);
    }
  }, [data, isDeliveryLetterList, isPremadeletter]);

  // 确认拼单
  const combineConfirm = useCallback(() => {
    // 打开拼单顺序设置Modal
    setMergeSortVisible(true);
  }, []);

  const getMinTruckLoadingLimit = (data: any[]) => {
    if (data?.length) {
      return data.reduce((min: any, current: any) =>
        (current.truckLoadingLimit < min.truckLoadingLimit ? current : min), data[0]);
    }
    return null;
  }

  // 拼车校验
  const doMergeValidate = useCallback((data: any[]) => {
    if (!data || data.length === 0) return false;

    const base = data[0];
    const { orgCode } = base;

    // 定义需要校验的字段
    const fieldsToCheck = [
      'orgCode',
      'truckPlateNumber',
      'driverName',
      'driverContactNumber',
    ];

    // 如果不是配送单且不是南方工厂，添加额外的校验字段
    if (!(isDeliveryLetterList && isSouthFactory(orgCode))) {
      fieldsToCheck.push('truckLoadingLimit');
    }

    // 检查是否有任何一项不符合校验条件
    return !data.some(item =>
      fieldsToCheck.some(field => item[field] !== base[field])
    );
  }, [isDeliveryLetterList]);

  const truckOverloadCheck = (item: any) => {
    return item.doQuantity &&
      item.orgCode === "1006" &&
      isVehicleNum(item.truckPlateNumber) &&
      item.businessDivision === "ACM" &&
      ["40", "42", "01"].includes(item.divisionCode) &&
      item.deliveryLocationCode?.startsWith("1") &&
      item.deliveryType === "自提"
  };

  const packingUnitCheck = useCallback((data: any) => {
    const isCancelCheck = data.some((item: any) => item.packingUnit === "PC" && !item.conversionFactorGross);
    if (isCancelCheck) {
      return true;
    }
    let totalDoQuantity: number = 0;
    let hasPC = false;
    let hasTruckOverloadCheck = false;
    data.forEach((item: any) => {
      const doQuantity = parseFloat(item.doQuantity);
      const conversionFactor = item.conversionFactor ? parseFloat(item.conversionFactor) : 1;
      const conversionFactorGross = item.conversionFactorGross ? parseFloat(item.conversionFactorGross) : 1;
      if (!hasTruckOverloadCheck) {
        hasTruckOverloadCheck = truckOverloadCheck(item);
      }
      switch (item.packingUnit) {
        case "TO":
        case "MT":
          totalDoQuantity += doQuantity;
          break;
        case "BAG":
          totalDoQuantity += doQuantity * conversionFactor;
          break;
        case "KG":
          totalDoQuantity += doQuantity * 0.001;
          break;
        case "PC":
          totalDoQuantity += doQuantity * conversionFactorGross;
          hasPC = true;
          break;
      }
    });
    // 拼单时，如果存在南通车提提单拼单超过33吨，则不允许拼单
    if (hasTruckOverloadCheck && totalDoQuantity > 33) {
      message.error("车提最大提货数量不能超过33吨。");
      return false;
    }

    const truckLoadingLimit = hasPC ? getMinTruckLoadingLimit(data)?.truckLoadingLimit : parseFloat(data[0].truckLoadingLimit);
    if ((isNorthFactory(data[0].orgCode)) && truckLoadingLimit && totalDoQuantity > parseFloat(truckLoadingLimit)) {
      message.error(`拼单提单的总重量已经超过限载吨位 ${truckLoadingLimit}`);
      return false;
    }
    return true;
  }, []);

  const doMergeHandler = useCallback(() => {
    let isAllowMergeAction = data.some((item: any) => item.doStatus === "受理中" || item.doStatus === "已拒绝");
    if (!isAllowMergeAction && isDeliveryLetterList) {
      message.error("当前提货委托函状态不符合拼单要求");
      return;
    }

    if (flag === 1) {
      setVisible(true);
      return;
    }

    // 拼单运输信息校验: 
    isAllowMergeAction = doMergeValidate(data);
    if (!isAllowMergeAction) {
      setShowTransportModal(true);
      return;
    }

    if (!packingUnitCheck(data)) return;
    combineConfirm();
  }, [data, isDeliveryLetterList, combineConfirm, flag, doMergeValidate, packingUnitCheck]);

  // 拼单执行
  const doCombine = useCallback(() => {
    const temp = dataSource.map((val: any) => val.doId);
    const mergeDoPayload: any = {
      dscDoIds: temp,
      internalMerge: isDeliveryLetterList,
    };
    if (checked) {
      mergeDoPayload.customerMerge = checked;
    }
    setLoading(true);
    mergeDo(mergeDoPayload)
      .then((res: any) => {
        if (res.code && res.code === "99999") {
          // 99999 提示后端的错误信息
          message.error(res.errorMsg);
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
        message.error("撤回数据失败，请稍后重试。");
      })
      .finally(() => {
        setLoading(false);
        setMergeSortVisible(false);
        props.getTable();
      });
  }, [isDeliveryLetterList, checked, props, dataSource]);

  // 判断是否激活拼单按钮
  // 1、只要包含了直连客户的均不允许拼单
  const isAllowCombineBtn = (dataSource: any) => {
    if (dataSource) {
      const filterData = dataSource.filter((item: any) => item.entrustNo);
      return filterData.length > 0;
    }
    return true;
  };

  const onHandleModalClose = useCallback(() => {
    setVisible(false);
  }, []);

  const onHandleTransportModalClose = useCallback(() => {
    setShowTransportModal(false);
  }, []);

  const onHandleBusinessLineModalClose = useCallback(() => {
    setShowBusinessLineModal(false);
  }, []);

  const onHandleMergeSortModal = useCallback(() => {
    setMergeSortVisible(false);
  }, []);

  const onSortEnd = useCallback(({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    if (oldIndex !== newIndex) {
      // 交换位置
      let datas = [].concat(dataSource);
      datas.splice(oldIndex, 1, ...datas.splice(newIndex, 1, datas[oldIndex]));
      const newDatasTemp = datas.filter(el => !!el)?.map((val: any, index: number) => {
        let valTemp = val;
        if (isPremadeletter || isDeliveryLetterList) {
          valTemp.index = index;
        }
        valTemp.sortOrder = index + 1;
        return valTemp;
      });
      setDataSource(newDatasTemp);
    }
  }, [dataSource, isDeliveryLetterList, isPremadeletter]);


  const DraggableContainer = useCallback(
    (props: any) => {
      return (
        <SortableContainers
          useDragHandle
          disableAutoscroll
          helperClass="row-dragging"
          onSortEnd={onSortEnd}
          {...props}
        />
      );
    },
    [onSortEnd]
  );

  // const DraggableBodyRow: React.FC<any> = ({ className, style, ...restProps }) => {
  //   // function findIndex base on Table rowKey props and should always be a right array index
  //   const index = dataSource.findIndex((x: any) => x.index === restProps['data-row-key']);
  //   return <SortableItem index={index} {...restProps} />;
  // };

  const DraggableBodyRow = useCallback(({ className, style, ...restProps }) => {
    const index = dataSource.findIndex((x: any) => x.index === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  }, [dataSource])

  const onChange = useCallback((e: any) => {
    setChecked(e.target.checked);
  }, []);

  return (
    <>
      <Button
        type={text ? "text" : "primary"}
        onClick={doMergeHandler}
        style={style}
        data-testid="combine-btn"
        disabled={data.length === 0 || isAllowCombineBtn(data)}
      >
        拼单
      </Button>
      <Modal
        title="拼单提醒"
        width={800}
        visible={flag === 1 && visible}
        centered
        maskClosable={false}
        onCancel={onHandleModalClose}
        footer={
          <Button data-testid="confirm-btn" type="primary" onClick={onHandleModalClose}>
            确认
          </Button>
        }
      >
        <span className="have-been-combine">存在已拼单的提单，不能重复拼单，请重新选择。</span>
      </Modal>

      <Modal
        title="拼单提醒"
        width={800}
        visible={showBusinessLineModal}
        centered
        maskClosable={false}
        onCancel={onHandleBusinessLineModalClose}
        footer={
          <Button data-testid="confirm-btn" type="primary" onClick={onHandleBusinessLineModalClose}>
            确认
          </Button>
        }
      >
        <span className="have-been-combine">非包装油禁止不同产品拼车</span>
      </Modal>

      <Modal
        title="拼单提醒"
        width={800}
        visible={showTransportModal}
        centered
        maskClosable={false}
        onCancel={onHandleTransportModalClose}
        footer={
          <Button data-testid="confirm-btn" type="primary" onClick={onHandleTransportModalClose}>
            确认
          </Button>
        }
      >
        <span className="have-been-combine">{
          `拼车提单必须拥有相同的卖方、车号、司机姓名、手机号${isDeliveryLetterList && isSouthFactory(data[0]?.orgCode) ? "" : "和车辆限载吨位"}`
        }</span>
      </Modal>

      <Modal
        title="拼单提醒"
        width={800}
        visible={mergeSortVisible}
        centered
        maskClosable={false}
        forceRender
        onCancel={onHandleMergeSortModal}
        footer={
          <Button data-testid="confirm-btn" loading={loading} type="primary" onClick={doCombine}>
            确认
          </Button>
        }
      >
        <div className="combine-tips" style={{ textAlign: "center", marginBottom: "20px" }}>
          <div>您正在进行拼单操作，请确认以下信息及拼单顺序是否正确</div>
          <div>嘉吉系统对提货量会按照拼单先后顺序进行拆分，如拼单顺序需要调整请拖动 <MenuOutlined style={{ cursor: "grab", color: "#999" }} /> 图标进行调整</div>
        </div>
        <Table
          dataSource={dataSource}
          columns={columns}
          rowKey="index"
          scroll={{ x: "max-content" }}
          components={{
            body: {
              wrapper: DraggableContainer,
              row: DraggableBodyRow
            }
          }}
          pagination={false}
        />
        {
          !isDeliveryLetterList && <Checkbox style={{ marginTop: "20px" }} onChange={onChange} checked={checked}>跨客户拼单</Checkbox>
        }
      </Modal>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  userPermissions: state.userPermissions
});

export default connect(mapStateToProps, null)(CombineButton);