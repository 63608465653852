import { useCallback, useEffect, useState } from "react";
import { Button, message, Modal } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { isMobileDevice } from "@/utils";
import { getCancelDo } from "@/api/deliveryList";

interface Props {
  data: any;
  getTable: any;
  permissions?: any;
  searchValues: any;
}

const RetractButton = (props: Props) => {
  const { data, getTable, permissions, searchValues } = props;

  const [externalVisible, setExternalVisible] = useState(false);
  const [disable, setDisable] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClick = useCallback(() => {
    if (permissions.CNExternalUsers) {
      setExternalVisible(true);
    }
  }, [permissions.CNExternalUsers]);

  // 撤回
  const handleExternalDelete = useCallback(() => {
    const { doNumber } = data;
    setLoading(true);
    getCancelDo({}, { doId: doNumber, type: "cancelDo" })
      .then((response: any) => {
        if (response.code && response.code === "99999") {
          message.error(response.errorMsg);
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
        message.error("撤回数据失败，请稍后重试。");
      })
      .finally(() => {
        setLoading(false);
        setExternalVisible(false);
        getTable(searchValues);
      });
  }, [data, getTable, searchValues]);

  useEffect(() => {
    if (permissions.CNInternalUsers) {
      // 内部用户
      // ----------------new CRQ, 内部用户全部置灰;
      setDisable(true);
    } else if (permissions.CNExternalUsers) {
      setDisable(
        !((data.doApplicationStatus === "信用审核失败" && !data.entrustNo) || (data.cancelEnabled && !data.entrustNo))
      );
    }
  }, [data, permissions]);

  const onHandleModalClose = useCallback(() => {
    setExternalVisible(false);
  }, []);

  return (
    <>
      <Button onClick={handleClick} type="text" className="delivery-list-operation-btn" disabled={disable}>
        {isMobileDevice() ? "撤回提单" : <CloseCircleOutlined className="table-retract-btn" />}
      </Button>
      <Modal
        title="取消提货委托函"
        visible={externalVisible}
        centered
        maskClosable={false}
        width={400}
        onCancel={onHandleModalClose}
        footer={
          <Button loading={loading} type="primary" onClick={handleExternalDelete}>
            确定
          </Button>
        }
      >
        <span>您确定取消提货委托函申请吗？</span>
      </Modal>
    </>
  );
};

export default RetractButton;
