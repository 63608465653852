import { useCallback, useEffect, useRef, useState } from "react";
import { AutoComplete, message } from "antd";
import { EditFilled } from "@ant-design/icons";
import {
  getContractDetailsByTypeVague,
  getDriverMessage,
  getTruckMessage,
  getUnilab,
  getUserRemark
} from "@/api/deliveryLetterList";
import { getContractDetailsById } from "@/api/contractReport";
import { updateDos } from "@/api/deliveryOrder";
import { addZeroToBatch, cutBatchNoZero, getFilterData, formatStrTrim } from "@/utils/formatUtils";
import "./index.scss";

interface Props {
  data: any;
  name: string;
  initValue?: any;
  width?: number;
  getTableData: any;
  setLoadingStatus: any;
  autoComplete?: boolean;
}
let flag = true;

const EditColumn = (props: Props) => {
  const { data, name, initValue, width, getTableData, setLoadingStatus } = props;
  const [options, setOptions] = useState([]);
  const [secOptions, setSecOptions] = useState([]);
  const [value, setValue] = useState(initValue);
  const [className, setClassName] = useState("");
  const [isFocus, setIsFocus] = useState(true);
  const [showInput, setShowInput] = useState(false);
  const [isSelect, setIsSelect] = useState(false);
  const input: any = useRef();

  const update = useCallback(
    (data: any) => {
      updateDos([data], {
        type: "editDo"
      })
        .then((res: any) => {
          if (res.code === "99999") {
            message.error(res.errorMsg);
            return;
          }
          getTableData();
        })
        .catch((exception: any) => {
          console.log(`Failure: ${exception}`);
          if (exception?.errorMsg) {
            message.error(exception.errorMsg);
          } else {
            message.error("抱歉，系统连接出现问题，请稍后再试。");
          }
        });
    },
    [getTableData]
  );

  const handleBatchEditCallbak = useCallback((value: any, temp: any, contractDetails: any) => {
    if (Object.keys(contractDetails)?.length > 0) {
      // 如果合同的批次号没有改变，则不拉取最新的合同信息
      const batchChange = addZeroToBatch(value) !== temp.batch;
      temp.batch = addZeroToBatch(value);
      temp = batchChange ? { ...temp, ...contractDetails } : { ...temp };
      update(temp);
    } else {
      message.error("请输入有效的合同行项目编号！");
    }
  }, [update]);

  const doNameNotContractNo = useCallback(
    async (temp: any) => {
      switch (name) {
        case "unilab":
          temp.unilab = value;
          break;
        case "internalMemo":
          temp.internalMemo = value;
          break;
        case "cycleId":
          temp.cycleId = value;
          break;
        case "emergencyReceiveAddress":
          temp.emergencyReceiveAddress = value;
          break;
        case "nominationNumber":
          temp.nominationNumber = value;
          break;
        case "truckPlateNumber":
          temp.truckPlateNumber = formatStrTrim(value, true);
          const truckLoadingLimit: any = options.filter((val: any) => val.value === data.truckPlateNumber);
          if (truckLoadingLimit.length > 0) {
            temp.truckLoadingLimit = truckLoadingLimit[0].entryvalue[0];
          }
          break;
        case "truckLoadingLimit":
          temp.truckLoadingLimit = value;
          break;
        case "driverName":
          temp.driverName = formatStrTrim(value, true);
          const driverContactNumber: any = options.filter((val: any) => val.value === data.driverName);
          if (driverContactNumber.length > 0) {
            temp.driverContactNumber = driverContactNumber[0].entryvalue[0];
          }
          break;
        case "driverContactNumber":
          temp.driverContactNumber = value;
          break;
        case "distributionNo":
          temp.distributionNo = value;
          break;
        default:
          break;
      }

      if (name === "batch") {
        const contractDetails =
          (await getContractDetails(data.contractNo, data.customerCode, addZeroToBatch(value))) || {};
        handleBatchEditCallbak(value, temp, contractDetails);
        return false;
      } else if (name === "driverContactNumber") {
        const reg = /^[1-9]\d{10}$/;
        if (temp.driverContactNumber?.length !== 11 || !reg.test(temp.driverContactNumber)) {
          message.error("请输入正确的联系方式!");
          return false;
        }
      }
      update(temp);
      return true;
    },
    [data, name, options, update, value, handleBatchEditCallbak]
  );

  const handleContractNoEditCallback = useCallback((value: any, temp: any, contractDetails: any) => {
    if (contractDetails && Object.keys(contractDetails).length > 0) {
      const contractNoChange = value !== temp.contractNo;
      temp.contractNo = value;
      // 如果合同号没有改变，则不拉取
      const updateData = contractNoChange ? { ...temp, ...contractDetails } : { ...temp };
      update(updateData);
    } else {
      message.error("请输入有效的合同编号。");
    }
  }, [update]);

  const onBlur = useCallback(async () => {
    setIsFocus(true);
    let temp = data;
    temp.haveEntry = "true";

    if (value === "") {
      if (name === "contractNo") {
        message.error("此项为必填");
        setClassName(className + " error-autocomplete-border");
        return;
      }
      if (name === "batch") {
        setClassName(className + " error-autocomplete-border");
        return;
      }
    } else {
      setClassName(className.replace(" error-autocomplete-border", ""));
    }

    if (name !== "contractNo") {
      const isOk = doNameNotContractNo(temp);
      if (!isOk) {
        return;
      }
    } else {
      const contractDetails = await getContractDetails(value, data.customerCode, data.batch);
      // 编辑合同号后执行回调处理
      handleContractNoEditCallback(value, temp, contractDetails);
    }
    setShowInput(false);
  }, [className, data, name, handleContractNoEditCallback, doNameNotContractNo, value]);

  const getOptions = (ApiName: any, value: string) => {
    ApiName({
      searchParams: value
    })
      .then((res: any) => {
        if (res.code && res.code === "99999") {
          // 99999 提示后端的错误信息
          message.error(res.errorMsg);
        } else {
          const data = res.data.history;
          const temp =
            data &&
            data.map((val: any) => {
              return {
                label: val.entryKey,
                value: val.entryKey,
                entryvalue: val.entryValue
              };
            });
          const options = getFilterData(temp, value);
          setOptions(options);
        }
      })
      .finally(() => {
        setLoadingStatus(false);
      });
  };

  // 获取批次
  const getBatch = (value: any) => {
    setIsFocus(false);
    getContractDetailsByTypeVague({
      contractNo: data.contractNo,
      batch: addZeroToBatch(value)
    })
      .then((res: any) => {
        if (res.code && res.code === "99999") {
          // 99999 提示后端的错误信息
          message.error(res.errorMsg);
        } else {
          const data = res.data.contract?.batch;
          const temp = data
            ? data.map((val: any) => {
              return {
                label: cutBatchNoZero(val.batch),
                value: cutBatchNoZero(val.batch)
              };
            })
            : [];
          setOptions(temp);
        }
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setLoadingStatus(false);
      });
  };

  const getSuggestion = (inputValue: any) => {
    setOptions([]);
    setSecOptions([]);
    switch (name) {
      case "batch":
        getBatch(inputValue);
        break;
      case "unilab":
        setIsFocus(false);
        getUnilab({
          searchParams: inputValue,
          productCode: data.productCode,
          businessDivision: data.businessDivision || "",
          orgCode: data.orgCode,
          customerCode: data.customerCode
        })
          .then((res: any) => {
            if (res.code && res.code === "99999") {
              // 99999 提示后端的错误信息
              message.error(res.errorMsg);
            } else {
              const data = res.data.unilab;
              const temp =
                data &&
                data.map((val: any) => {
                  return {
                    label: val.name,
                    value: val.name
                  };
                });
              setOptions(temp);
            }
          })
          .finally(() => {
            setLoadingStatus(false);
          });
        break;
      case "internalMemo":
        setIsFocus(false);
        getUserRemark({
          searchTerm: inputValue
        })
          .then((res: any) => {
            if (res.code && res.code === "99999") {
              // 99999 提示后端的错误信息
              message.error(res.errorMsg);
            } else {
              const data = res.data.history;
              const temp =
                data &&
                data.map((val: any) => {
                  return {
                    label: val,
                    value: val
                  };
                });
              setOptions(temp);
            }
          })
          .finally(() => {
            setLoadingStatus(false);
          });
        break;
      case "truckPlateNumber":
      case "truckLoadingLimit":
        setIsFocus(false);
        getOptions(getTruckMessage, inputValue);
        break;
      case "driverName":
      case "driverContactNumber":
        setIsFocus(false);
        getOptions(getDriverMessage, inputValue);
        break;
      default:
        setLoadingStatus(false);
    }
  };

  const onFocus = useCallback(() => {
    let inputValue = value;
    if (flag) {
      setLoadingStatus(true);
      flag = false;
    }
    if (value === "-") {
      onChange("");
      inputValue = "";
    }
    getSuggestion(inputValue);
    setIsSelect(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setLoadingStatus, value]);

  const onSelect = useCallback((value: any) => {
    setValue(value);
    setIsSelect(true);
  }, []);

  const getContractDetails = (contractNo: any, customerCode: string, contractItem: string) => {
    return getContractDetailsById({
      contractNo: contractNo,
      customerCode,
      batch: contractItem
    })
      .then((res: any) => {
        if (res?.data?.contract) {
          const { batch, contractDetailInfo } = res.data.contract;
          const contractDetailsObj = contractDetailInfo && batch ? { ...contractDetailInfo, ...batch[0] } : {};
          if (Object.keys(contractDetailsObj)?.length > 0) {
            const details = {
              deliveryLocation: contractDetailsObj.deliveryLocation,
              packageSpecification: contractDetailsObj.packageSpecification,
              packageSpecificationCn: contractDetailsObj.packageSpecificationCn,
              productName: contractDetailsObj.productName,
              contractNo: contractDetailsObj.contractNo,
              batch: contractDetailsObj.actualBatchNo,
              packingUnitDesc: contractDetailsObj.packingUnitDesc,
              buyerSellerOption: contractDetailsObj.buyerSellerOption,
              incotermsCode: contractDetailsObj.incotermsCode,
              executionEndDate: contractDetailsObj.executionEndDate,
              materialSalesText: contractDetailsObj.materialSalesText
            };
            return details;
          }
          return {};
        }
      })
      .catch((exception: any) => {
        console.error(`Failure: ${exception}`);
      });
  };

  const editClick = useCallback(() => {
    setOptions([]);
    setSecOptions([]);
    setShowInput(true);
  }, []);

  const onChange = useCallback(
    (data: any) => {
      if (name === "batch") {
        getBatch(data);
      }
      setValue(data);
      getSuggestion(data);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [name]
  );

  useEffect(() => {
    setValue(initValue);
  }, [initValue]);

  useEffect(() => {
    if (isSelect) {
      input?.current.blur();
    }
  }, [isSelect]);

  useEffect(() => {
    if (value !== "-") {
      // 抽取公共方法赋值
      const setValueRewrite = (key: any) => {
        if (data[key] !== value) {
          setValue(data[key]);
        }
      };
      switch (name) {
        case "contractNo":
          setValueRewrite("contractNo");
          break;
        case "batch":
          if (data.contractItem !== value) {
            setValue(cutBatchNoZero(data.contractItem));
          }
          break;
        case "emergencyReceiveAddress":
          setValueRewrite("emergencyReceiveAddress");
          break;
        case "cycleId":
          setValueRewrite("cycleId");
          break;
        case "nominationNumber":
          setValueRewrite("nominationNumber");
          break;
        case "unilab":
          setValueRewrite("unilab");
          break;
        case "internalMemo":
          setValueRewrite("internalMemo");
          break;
        case "truckPlateNumber":
          setValueRewrite("truckPlateNumber");
          break;
        case "truckLoadingLimit":
          setValueRewrite("truckLoadingLimit");
          break;
        case "driverName":
          setValueRewrite("driverName");
          break;
        case "driverContactNumber":
          setValueRewrite("driverContactNumber");
          break;
        case "distributionNo":
          setValueRewrite("distributionNo");
          break;
        default:
          break;
      }
    }
    setShowInput(false);
    flag = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (name === "truckLoadingLimit" || name === "driverContactNumber") {
      const val: any = options.filter((val: any) => {
        if (name === "truckLoadingLimit") return val.value === data.truckPlateNumber;
        return val.value === data.driverName;
      })[0];
      const temp = val?.entryvalue.map((item: any) => {
        return {
          label: item,
          value: item
        };
      });
      setSecOptions(temp);
    }
  }, [data, name, options]);

  useEffect(() => {
    let temp = className;
    temp = temp + (isFocus ? " hide-input-border" : "");
    setClassName(temp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocus]);

  const getInputElement = () => {
    return (
      <AutoComplete
        ref={input}
        options={name === "truckLoadingLimit" || name === "driverContactNumber" ? secOptions : options}
        style={{ width: width ? width : 100 }}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onChange}
        onSelect={onSelect}
        value={value}
        autoFocus={true}
        defaultOpen={true}
        className={className}
        dropdownClassName="edit-column-autocomplete"
      />
    );
  };

  const getTextElement = () => {
    return (
      <div className="disabled-input-component" onClick={editClick} role="button" data-testid="edit" tabIndex={0}>
        <span>{value || "-"}</span>
        <div className="edit-icon">
          <EditFilled onClick={editClick} style={{ cursor: "pointer" }} />
        </div>
      </div>
    );
  };

  return <>{!showInput ? getTextElement() : getInputElement()}</>;
};

export default EditColumn;
