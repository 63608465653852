import { useCallback, useEffect, useState } from "react";
import { Select, Input, Button, Form, Row, Col, Collapse, Divider, DatePicker, message, Spin } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { allSupplier, allBusinessLine, logisticsStatus, isCombineOptions, b2bTagOptions, qualityFileOptions, poundFileOptions, typeOfShippingOptions } from "@/utils/constant";
import * as type from "../../constant";
import MyAutoComplete from "@/pages/common/autoComplete";
import { getProductDetailList, getSapFirmDetailsList, getShipAddressDescList } from "@/api/contractReport";
import { getCode } from "@/utils/common";
import { getFilterData } from "@/utils/formatUtils";
import moment from "moment";

const { Panel } = Collapse;

interface Props {
  getSearchValue: any;
  switcher: any;
  getOffset: any;
  contractNo?: any;
  getSort: any;
  setSortOrder: any;
}

const SearchFilter = (props: Props) => {
  const { getSearchValue, switcher, getOffset, contractNo, getSort, setSortOrder } = props;
  const [form] = Form.useForm();
  const [isClick, setIsClick] = useState(false);
  const [isExpand, setIsExpand] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [shipAddressDescOptions, setShipAddressDescOptions] = useState([]);
  const [expandCollapseText, setExpandCollapseText] = useState("展开高级筛选");
  const [searchValue, setSearchValue] = useState({
    divisionCode: "",
    orgCode: "",
    productCode: "",
    accountName: "",
    createdDateFrom: "",
    createdDateTo: ""
  } as any);

  const handelExpandCollapse = useCallback(() => {
    setIsClick(!isClick);
    setIsExpand(!isExpand);
    setExpandCollapseText(isExpand ? "展开高级筛选" : "收起高级筛选");
  }, [isClick, isExpand]);

  const getCurrentAndThreeMonthsAgo = () => {
    const currentDate = moment(); // 获取当前日期
    const threeMonthsAgo = moment().subtract(3, 'months'); //三个月前的日期
    const firstDayOfThreeMonthsAgo = threeMonthsAgo.startOf('month'); // 获取三个月前的月份的第一天
    return [moment(firstDayOfThreeMonthsAgo.format("YYYY-MM-DD"), "YYYY-MM-DD"), moment(currentDate.format("YYYY-MM-DD"), "YYYY-MM-DD")];
  };

  useEffect(() => {
    if (contractNo) {
      form.setFieldsValue({ contractNumber: contractNo });
    }
  }, [contractNo, form]);

  // 切换卖方/业务线
  useEffect(() => {
    if (switcher) {
      setCustomerOptions([]);
      setProductOptions([]);
      form.setFieldsValue({
        divisionCode: switcher.divisionCode === null ? "" : switcher.divisionCode,
        orgCode: switcher.orgCode === null ? "" : switcher.orgCode
      });
      let value: any = searchValue;
      value.divisionCode = switcher.divisionCode === null ? "" : switcher.divisionCode;
      value.orgCode = switcher.orgCode === null ? "" : switcher.orgCode;
      value.isNew = true;
      // 获取提单提交日期范围
      const createdDate = getCurrentAndThreeMonthsAgo();
      value.createdDateFrom = createdDate[0]?.format("YYYY-MM-DD") || "";
      value.createdDateTo = createdDate[1]?.format("YYYY-MM-DD") || "";
      getSearchValue(value);
      getOffset(0, 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, switcher.divisionCode, switcher.orgCode]);

  const getCustomer = useCallback(() => {
    setIsSearchLoading(true);
    setCustomerOptions([]);
    getSapFirmDetailsList({
      customerName: searchValue.accountName,
      orgCode: searchValue.orgCode,
      divisionCode: searchValue.divisionCode
    })
      .then((res: any) => {
        if (res.data) {
          if (res.data.customerList?.length) {
            let options = res.data.customerList.map((val: any) => {
              return {
                label: `${val.customerName}(${val.customerCode})`,
                value: `${val.customerName}(${val.customerCode})`,
                key: val.customerCode
              };
            });
            options = getFilterData(options, searchValue.accountName);
            setCustomerOptions(options);
          } else {
            message.error("No Results Found");
          }
        }
      })
      .catch(() => {
        setCustomerOptions([]);
      })
      .finally(() => {
        setIsSearchLoading(false);
      });
  }, [searchValue.accountName, searchValue.divisionCode, searchValue.orgCode]);

  const getProductName = useCallback(() => {
    setIsSearchLoading(true);
    setProductOptions([]);
    getProductDetailList({
      salesOrgs: searchValue.orgCode,
      productName: searchValue.productName
    })
      .then((res: any) => {
        if (res.data) {
          if (res.data.products.length > 0) {
            const options = res.data.products.map((val: any) => {
              return {
                label: `${val.productName}(${val.productCode})`,
                value: `${val.productName}(${val.productCode})`,
                key: val.productCode
              };
            });
            setProductOptions(options);
          } else {
            setProductOptions([]);
            message.error("No Results Found");
          }
        }
      })
      .finally(() => {
        setIsSearchLoading(false);
      });
  }, [searchValue.orgCode, searchValue.productName]);

  // 获取嘉吉发货地址
  const getShipAddressDesc = useCallback((params: object) => {
    setIsSearchLoading(true);
    setShipAddressDescOptions([]);
    getShipAddressDescList(params)
      .then((res: any) => {
        if (res.data?.plantLocations?.length) {
          const options: any = [];
          res.data.plantLocations.forEach((val: any) => {
            let key = val.locations.location_code;
            if (!options.find((option: any) => option.key === key)) {
              options.push({
                label: val.locations.location_name,
                value: val.locations.location_code,
                key
              });
            }
          });
          setShipAddressDescOptions(options);
        } else {
          message.error("No Results Found");
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
      })
      .finally(() => {
        setIsSearchLoading(false);
      });
  }, []);

  // 卖方变更后清空嘉吉发货地址
  const handleOrgCodeChange = useCallback(() => {
    form.setFieldsValue({ deliveryLocationCode: null });
    setShipAddressDescOptions([]);
  }, [form]);

  // 处理嘉吉发货地址聚焦
  const handleFocus = useCallback(() => {
    if (!shipAddressDescOptions.length) {
      const orgCode = form.getFieldValue("orgCode") || switcher.orgCode;
      getShipAddressDesc({ orgCode: orgCode && orgCode !== "All" ? orgCode : "ALL", toleranceTypeCode: "plantLocations" })
    }
  }, [form, getShipAddressDesc, switcher.orgCode, shipAddressDescOptions]);

  // 查询
  const onFinish = useCallback(
    (values: any) => {
      let temp = {
        completedDateFrom: values.completedDate ? values.completedDate[0].format("YYYY-MM-DD") : "",
        completedDateTo: values.completedDate ? values.completedDate[1].format("YYYY-MM-DD") : "",
        contractItem: values.contractItem,
        contractNumber: values.contractNumber,
        createdDateFrom: values.createdDate ? values.createdDate[0].format("YYYY-MM-DD") : "",
        createdDateTo: values.createdDate ? values.createdDate[1].format("YYYY-MM-DD") : "",
        customerCode: getCode(values.accountName),
        deliveryStatus: values.deliveryStatus,
        distributionNo: values.distributionNo,
        divisionCode: values.divisionCode,
        isNew: true,
        orgCode: values.orgCode,
        packageSpecification: values.packageSpecification,
        productName: "",
        productCode: getCode(values.productName).replace(/\b(0+)/gi, ""),
        warehouseTag: values.warehouseTag,
        doMerge: values.doMerge === "All" ? null : values.doMerge,
        directOrder: values.directOrder,
        salesOrderNumber: values.salesOrderNumber, // 新增提单号查询
        qualityFile: values.qualityFile,
        poundFile: values.poundFile,
        deliveryLocationCode: values.deliveryLocationCode,
        approveTimeFrom: values.approveTime ? values.approveTime[0].format("YYYY-MM-DD") : "",
        approveTimeTo: values.approveTime ? values.approveTime[1].format("YYYY-MM-DD") : "",
        transportTool: values.transportTool
      };
      // 增加 “外库/其他提单”的物流状态，因为外库/其他提单不是真正的物流状态，所以使用warehouseTag代表“外库/其他提单”
      if (temp.deliveryStatus === "外库/其他提单") {
        temp.deliveryStatus = "";
        temp.warehouseTag = "true";
      } else {
        temp.warehouseTag = "";
      }
      let sort = {
        sortDirection: type.DO_SORT_BY_ID_DESC,
        sort: type.DO_DESC
      };
      getSort(sort, true);
      setSortOrder(false);
      setSearchValue(temp);
      getSearchValue(temp);
      getOffset(0, 1);
    },
    [getOffset, getSearchValue, getSort, setSortOrder]
  );

  const onValueChange = useCallback(
    (changeValue: any, allValues: any) => {
      // 增加 “外库/其他提单”的物流状态，因为外库/其他提单不是真正的物流状态，所以使用warehouseTag代表“外库/其他提单”
      if (allValues.deliveryStatus === "外库/其他提单") {
        allValues.deliveryStatus = "";
        allValues.warehouseTag = "true";
      } else {
        allValues.warehouseTag = "";
      }
      if (changeValue.contractNumber !== undefined) {
        const value = changeValue.contractNumber.replace(/[^\-?\d.]/g, "");
        form.setFieldsValue({ contractNumber: value });
        allValues.contractNumber = value;
      }
      const newSearchValues = Object.assign(searchValue, allValues);
      setSearchValue(newSearchValues);
    },
    [form, searchValue]
  );

  // 清空
  const resetValue = useCallback(() => {
    form.resetFields();
    form.setFieldsValue({
      divisionCode: switcher.divisionCode || "",
      orgCode: switcher.orgCode || "",
      contractNumber: "",
      createdDate: null
    });
    let value: any = {
      divisionCode: switcher.divisionCode || "",
      orgCode: switcher.orgCode || "",
      productCode: "",
      isNew: true,
      contractNumber: ""
    };
    let sort = {
      sortDirection: type.DO_SORT_BY_ID_DESC,
      sort: type.DO_DESC
    };
    setProductOptions([]);
    setShipAddressDescOptions([]);
    getSort(sort, true);
    setSortOrder(false);
    setSearchValue(value);
    getSearchValue(value);
    getOffset(0, 1);
  }, [form, getOffset, getSearchValue, getSort, setSortOrder, switcher.divisionCode, switcher.orgCode]);

  // 嘉吉发货地址筛选
  const filterOption = useCallback((input: string, option?: { label: string; value: string }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase()), []);
  
  return (
    <div className="search-filter">
      <div className="base-filter">
        <Form
          layout="vertical"
          onFinish={onFinish}
          form={form}
          autoComplete="off"
          onValuesChange={onValueChange}
          initialValues={{
            divisionCode: switcher.divisionCode || "",
            orgCode: switcher.orgCode || "",
            deliveryStatus: "",
            doMerge: "",
            distributionNo: "",
            productName: "",
            createdDate: getCurrentAndThreeMonthsAgo()
          }}
        >
          <Row gutter={{ xs: 8, md: 16 }}>
            <Col md={5} xs={24}>
              <Form.Item name="divisionCode">
                <Select
                  data-testid="divisionCode"
                  style={{ width: "100%" }}
                  options={allBusinessLine()}
                />
              </Form.Item>
            </Col>
            <Col md={5} xs={24}>
              <Form.Item name="orgCode">
                <Select
                  data-testid="orgCode"
                  style={{ width: "100%" }}
                  options={allSupplier()}
                  onChange={handleOrgCodeChange}
                />
              </Form.Item>
            </Col>
            <Col md={5} xs={24}>
              <Form.Item name="productName">
                <MyAutoComplete
                  onFocus={getProductName}
                  isLoading={isSearchLoading}
                  options={productOptions}
                  placeholder="请输入产品名称"
                  type="sap"
                  value={searchValue.productCode}
                />
              </Form.Item>
            </Col>
            <Col md={6} xs={24}>
              <Form.Item name="completedDate">
                <DatePicker.RangePicker
                  data-testid="completedDate"
                  style={{ width: "100%" }}
                  dropdownClassName="range-picker-drop-down-mobile"
                  placeholder={["提货完成日期", "提货完成日期"]}
                />
              </Form.Item>
            </Col>
            <Col md={3} xs={24}>
              <Form.Item>
                <Button
                  type="primary"
                  data-testid="submit"
                  htmlType="submit"
                  style={{ width: "100%" }}
                  icon={<SearchOutlined />}
                  disabled={isClick}
                >
                  查询
                </Button>
              </Form.Item>
            </Col>
          </Row>
          {/* 展开筛选 */}
          <Collapse
            style={{ marginTop: -25 }}
            ghost
            expandIconPosition="right"
            onChange={handelExpandCollapse}
            collapsible="header"
          >
            <Panel header={<span style={{ color: "#007ed9" }}>{expandCollapseText}</span>} key="1" forceRender={true}>
              <Divider dashed={true} style={{ width: "101%", margin: "-10px 0 13px -14px" }} />
              <Row gutter={{ xs: 8, md: 16 }}>
                <Col md={6} xs={24}>
                  <Form.Item label="提货单提交日期" name="createdDate">
                    <DatePicker.RangePicker
                      data-testid="createdDate"
                      dropdownClassName="range-picker-drop-down-mobile"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col md={6} xs={24}>
                  <Form.Item label="客户名称" name="accountName">
                    <MyAutoComplete
                      onFocus={getCustomer}
                      isLoading={isSearchLoading}
                      options={customerOptions}
                      placeholder="请输入公司名称"
                      type="sap"
                      value={searchValue.accountName}
                    />
                  </Form.Item>
                </Col>
                <Col md={6} xs={24}>
                  <Form.Item label="合同编号" name="contractNumber">
                    <Input data-testid="contractNumber" placeholder="请输入" />
                  </Form.Item>
                </Col>
                <Col md={6} xs={24}>
                  <Form.Item label="合同行项目编号" name="contractItem">
                    <Input data-testid="contractItem" placeholder="请输入" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={{ xs: 8, md: 16 }}>
                <Col md={6} xs={24}>
                  <Form.Item label="包装规格" name="packageSpecification">
                    <Input placeholder="请输入" />
                  </Form.Item>
                </Col>
                <Col md={6} xs={24}>
                  <Form.Item label="物流状态" name="deliveryStatus">
                    <Select
                      data-testid="deliveryStatus"
                      style={{ width: "100%" }}
                      options={logisticsStatus}
                      listHeight={300}
                    />
                  </Form.Item>
                </Col>
                <Col md={6} xs={24}>
                  <Form.Item label="是否拼单" name="doMerge">
                    <Select data-testid="doMerge" style={{ width: "100%" }} options={isCombineOptions} />
                  </Form.Item>
                </Col>
                <Col md={6} xs={24}>
                  <Form.Item label="配送订单" name="distributionNo">
                    <Select data-testid="distributionNo" style={{ width: "100%" }} options={isCombineOptions} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={{ xs: 8, md: 16 }}>
                {/* 新增提单头编号查询 */}
                <Col md={6} xs={24}>
                  <Form.Item label="提单头编号" name="salesOrderNumber">
                    <Input data-testid="salesOrderNumber" placeholder="请输入" />
                  </Form.Item>
                </Col>
                <Col md={6} xs={24}>
                  <Form.Item label="是否直连提单" name="directOrder">
                    <Select placeholder="请选择" options={b2bTagOptions} />
                  </Form.Item>
                </Col>
                <Col md={6} xs={24}>
                  <Form.Item label="是否有质量报告" name="qualityFile">
                    <Select placeholder="请选择" options={qualityFileOptions} />
                  </Form.Item>
                </Col>
                <Col md={6} xs={24}>
                  <Form.Item label="是否有电子磅单" name="poundFile">
                    <Select placeholder="请选择" options={poundFileOptions} />
                  </Form.Item>
                </Col>
                <Col md={6} xs={24}>
                  <Form.Item label="开单时间" name="approveTime">
                    <DatePicker.RangePicker
                      dropdownClassName="range-picker-drop-down-mobile"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col md={6} xs={24}>
                  <Form.Item label="嘉吉发货地址" name="deliveryLocationCode">
                    <Select
                      showSearch
                      allowClear
                      placeholder="请选择"
                      onFocus={handleFocus}
                      options={shipAddressDescOptions}
                      optionFilterProp="label"
                      filterOption={filterOption}
                      notFoundContent={isSearchLoading ? <Spin size="small" /> : null}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col md={6} xs={24}>
                  <Form.Item label="运输工具" name="transportTool">
                    <Select placeholder="请选择" options={typeOfShippingOptions} />
                  </Form.Item>
                </Col>
                <Col className="advance-btn-col">
                  <Form.Item>
                    <div className="advance-btn">
                      <Button
                        data-testid="reset-btn"
                        style={{ marginRight: 10, backgroundColor: "#5c5c5c" }}
                        onClick={resetValue}
                        type="primary"
                      >
                        清空
                      </Button>
                      <Button data-testid="submit-btn" type="primary" htmlType="submit">
                        立即查询
                      </Button>
                    </div>
                  </Form.Item>
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </Form>
      </div>
    </div>
  );
};

export default SearchFilter;
