import { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { Card, Popconfirm, Table, Button, Row, Col, Pagination, Spin } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import ConfirmSubmit from "./components/confirmSubmit";
import BatchEdit from "./components/batchEdit";
import RefuseButton from "./components/refuseButton";
import EditText from "./components/EditText";
import moment from "moment";
import FieldSetting from "@/pages/common/fieldSetting";
import DownloadReport from "@/pages/common/downloadReport";
import CombineButton from "@/pages/common/combineButton";
import CancelCombine from "@/pages/common/cancelCombine";
import * as type from "../../../constant";
import EditColumn from "./components/editColumn";
import { isHaveThisPermission, isMobileDevice, nullTruckLoadingLimit } from "@/utils/common";
import MobileTableOperation from "@/pages/common/mobileTableOperation";
import { cutBatchNoZero, setShipAddressDesc, compareDate } from "@/utils/formatUtils";
import RejectReason from "@/pages/common/rejectReason";
import ForceLock from "./components/forceLock";

interface Props {
  userDetails: any;
  data: any; // 数据
  TableButtonConfig: {
    isSpecialApproval?: boolean; // 特批
    isBatchEditButton?: boolean; // 批量编辑
    isRefuseButton?: boolean; // 拒绝
    isConfirmSubmitButton?: boolean; // 确认提交
    isApprovedButton?: boolean; // 审批通过
    isCombineButton?: boolean; // 拼单
    isCancelCombineButton?: boolean; // 取消拼单
    isDownloadButton?: boolean; // 下载报表
  };
  totalCount: any;
  fieldsDesc?: any;
  allFields?: any;
  getColumns?: any;
  getMetadata?: any;
  getTable: any;
  searchValue?: any;
  current: number;
  keys?: string;
  loading: boolean;
  setLoadingStatus: any;
  selectedRowKeys?: any;
  setSelectedRowKeys?: any;
  selectedRows?: any;
  setSelectedRows?: any;
  isRejectedPage?: boolean;
  isPendingEditPage?: boolean;
  isFulfillmentOrderPage?: boolean;
  isPendingPage?: boolean;
  downloadFileName?: string;
  downloadType?: string;
}

interface DataType {
  doId: string;
  doStatus: string;
  conversionFactor: any;
  buyer: string;
  submitDate: string;
  businessLine: string;
  productName: string;
  contractNo: string;
  actualContractNo: string;
  batch: string;
  doQuantity: number;
  doQuantityByPackage: number;
  packageSpecification: string;
  deliveryLocation: string;
  tankId: string;
  unilab: string;
  internalMemo: string;
  rejectReason: string;
  overrideStatus: string;
  truckPlateNumber: string;
  driverName: string;
  driverContactNumber: string;
  truckLoadingLimit: number;
  doMerge: boolean;
  doInternalMerge: boolean;
  warehouseTag: boolean;
  warehouse: string;
  estimatedDeliveryDate: string;
  creditCheckFailReason: string;
  operator: string;
  entrustNo: string;
  mergeRemark: string;
  contractRemainingQty: number;
  cdosId: number;
  arrivalDate: string;
  deliveryStatusEntity: any;
}

const TabTable = (props: Props) => {
  const allColumns = [
    { title: "平台序列号", dataIndex: "doId", key: "doId" },
    {
      title: "提单头编号",
      dataIndex: "salesOrderNumber",
      key: "salesOrderNumber",
      render: (_: any, record: any) => {
        return record.salesOrderNumber ? record.salesOrderNumber : "";
      }
    },
    {
      title: "提单行项目编号",
      dataIndex: "salesOrderItem",
      key: "salesOrderItem",
      render: (_: any, record: any) => {
        return record.salesOrderItem ? record.salesOrderItem : "";
      }
    },
    { title: "提货委托状态", dataIndex: "doStatus", key: "doApplicationStatus" },
    {
      title: "系统提货单号",
      dataIndex: "cdosId",
      key: "cdosId",
      render: (_: any, record: DataType) => {
        return record.cdosId ? record.cdosId : "-";
      }
    },
    { title: "买方", dataIndex: "buyer", key: "customerAccountName" },
    {
      title: "提货单提交日期",
      dataIndex: "submitDate",
      key: "doApplicationCreatedDate",
      render: (_: any, record: DataType) => {
        return record.submitDate ? moment(record.submitDate).format("YYYY-MM-DD HH:mm:ss") : "-";
      }
    },
    {
      title: "业务类型代码",
      dataIndex: "divisionCode",
      key: "divisionCode"
    },
    { title: "业务类型", dataIndex: "businessLine", key: "commodityCode" },
    { title: "产品名称", dataIndex: "productName", key: "productName" },
    {
      title: "合同编号",
      dataIndex: "contractNo",
      key: "contractNo",
      render: (_: any, record: any) => {
        return isEnabledAction(record) && isTwDelivery(record) ? (
          <EditColumn
            data={record}
            name="contractNo"
            setLoadingStatus={props.setLoadingStatus}
            width={150}
            initValue={record.contractNo ? record.contractNo : "-"}
            getTableData={getTable}
          />
        ) : (
          <span>{record.contractNo || "-"}</span>
        );
      }
    },
    {
      title: "合同行项目编号",
      dataIndex: "contractItem",
      key: "contractItem",
      render: (_: any, record: any) => {
        return isEnabledAction(record) && isTwDelivery(record) ? (
          <EditColumn
            data={record}
            name="batch"
            setLoadingStatus={props.setLoadingStatus}
            width={150}
            initValue={record.contractItem ? cutBatchNoZero(record.contractItem) : "-"}
            getTableData={getTable}
          />
        ) : (
          <span>{cutBatchNoZero(record.contractItem) || "-"}</span>
        );
      }
    },
    {
      title: props.isRejectedPage ? "计划提货数量（吨）" : "计划提货数量",
      dataIndex: "doQuantity",
      key: "requestedDoQty"
    },
    {
      title: "计划提货数量（箱/件）",
      dataIndex: "doQuantityByPackage",
      key: "requestedDoQtyByPackage",
      render: (_: any, record: DataType) => {
        return record.doQuantityByPackage ? record.doQuantityByPackage : "-";
      }
    },
    { title: "计数单位", dataIndex: "packingUnitDesc", key: "packingUnitDesc" },
    { title: "包装规格", dataIndex: "packageSpecificationCn", key: "package" },
    {
      title: "嘉吉发货地址",
      dataIndex: "shipAddressDesc",
      key: "shipAddressDesc",
      render: (_: any, record: DataType) => {
        return setShipAddressDesc(record);
      }
    },
    {
      title: "买方收货地址",
      dataIndex: "receiveAddressDesc",
      key: "receiveAddressDesc"
    },
    {
      title: "紧急/一次性买方收货地址",
      dataIndex: "emergencyReceiveAddress",
      key: "emergencyReceiveAddress",
      render: (_: any, record: any) => {
        return isEnabledAction(record) ? (
          <EditColumn
            data={record}
            name="emergencyReceiveAddress"
            setLoadingStatus={props.setLoadingStatus}
            width={150}
            initValue={record.emergencyReceiveAddress ? record.emergencyReceiveAddress : "-"}
            getTableData={getTable}
          />
        ) : (
          <span>{record.emergencyReceiveAddress || "-"}</span>
        );
      }
    },
    {
      title: "CycleId",
      dataIndex: "cycleId",
      key: "cycleId",
      render: (_: any, record: any) => {
        return isEnabledAction(record, "cycleId") ? (
          <EditColumn
            data={record}
            name="cycleId"
            setLoadingStatus={props.setLoadingStatus}
            width={150}
            initValue={record.cycleId ? record.cycleId : "-"}
            getTableData={getTable}
          />
        ) : (
          <span>{record.cycleId || "-"}</span>
        );
      }
    },
    {
      title: "船号",
      dataIndex: "nominationNumber",
      key: "nominationNumber",
      render: (_: any, record: any) => {
        return isEnabledAction(record, "nominationNumber") ? (
          <EditColumn
            data={record}
            name="nominationNumber"
            setLoadingStatus={props.setLoadingStatus}
            width={150}
            initValue={record.nominationNumber ? record.nominationNumber : "-"}
            getTableData={getTable}
          />
        ) : (
          <span>{record.nominationNumber || "-"}</span>
        );
      }
    },
    {
      title: "Unilab",
      dataIndex: "unilab",
      key: "unilab",
      render: (_: any, record: any) => {
        return isEnabledAction(record) ? (
          <EditColumn
            data={record}
            name="unilab"
            setLoadingStatus={props.setLoadingStatus}
            width={150}
            initValue={record.unilab ? record.unilab : "-"}
            getTableData={getTable}
          />
        ) : (
          <span>{record.unilab || "-"}</span>
        );
      }
    },
    {
      title: "内/外部备注",
      dataIndex: "internalMemo",
      key: "internalMemo",
      render: (_: any, record: any) => {
        return isEnabledAction(record) ? (
          <EditColumn
            data={record}
            name="internalMemo"
            setLoadingStatus={props.setLoadingStatus}
            width={80}
            initValue={record.internalMemo ? record.internalMemo : "-"}
            getTableData={getTable}
          />
        ) : (
          record.internalMemo || "-"
        );
      }
    },
    {
      title: "拒绝原因",
      dataIndex: "rejectReason",
      key: "rejectReason",
      render: (_: any, record: any) => {
        return <RejectReason data={record} />;
      }
    },
    { title: "发货要求", dataIndex: "shippingInstruction", key: "shippingInstruction" },
    { title: "提单打印信息", dataIndex: "shippingRemark", key: "shippingRemark" },
    {
      title: "大提单",
      dataIndex: "bigOrder",
      key: "bigOrder",
      render: (_: any, record: any) => {
        return record.bigOrder && record.bigOrder === "Y" ? "是" : "否";
      }
    },
    { title: "特批状态", dataIndex: "overrideStatus", key: "overrideStatus" },
    {
      title: "车/船号",
      dataIndex: "truckPlateNumber",
      key: "truckPlateNumber",
      render: (_: any, record: any) => {
        return isEnabledAction(record) ? (
          <EditColumn
            data={record}
            name="truckPlateNumber"
            setLoadingStatus={props.setLoadingStatus}
            width={80}
            initValue={record.truckPlateNumber ? record.truckPlateNumber : "-"}
            getTableData={getTable}
          />
        ) : (
          <span>{record.truckPlateNumber || "-"}</span>
        );
      }
    },
    { title: "运输方式", dataIndex: "transportTypeDesc", key: "transportTypeDesc" },
    { title: "运输工具", dataIndex: "transportTool", key: "transportTool" },
    {
      title: "承运人姓名",
      dataIndex: "driverName",
      key: "driverName",
      render: (_: any, record: any) => {
        return isEnabledAction(record) ? (
          <EditColumn
            data={record}
            name="driverName"
            setLoadingStatus={props.setLoadingStatus}
            width={60}
            initValue={record.driverName ? record.driverName : "-"}
            getTableData={getTable}
          />
        ) : (
          <span>{record.driverName || "-"}</span>
        );
      }
    },
    {
      title: "承运人联系方式",
      dataIndex: "driverContactNumber",
      key: "driverContactNumber",
      render: (_: any, record: any) => {
        return isEnabledAction(record) ? (
          <EditColumn
            data={record}
            name="driverContactNumber"
            setLoadingStatus={props.setLoadingStatus}
            width={100}
            initValue={record.driverContactNumber ? record.driverContactNumber : "-"}
            getTableData={getTable}
          />
        ) : (
          <span>{record.driverContactNumber || "-"}</span>
        );
      }
    },
    {
      title: "车辆限载吨位",
      dataIndex: "truckLoadingLimit",
      key: "truckLoadingLimit",
      render: (_: any, record: any) => {
        return isEnabledAction(record) ? (
          <EditColumn
            data={record}
            name="truckLoadingLimit"
            setLoadingStatus={props.setLoadingStatus}
            width={40}
            initValue={nullTruckLoadingLimit(record)}
            getTableData={getTable}
          />
        ) : (
          nullTruckLoadingLimit(record)
        );
      }
    },
    {
      title: "是否拼单",
      dataIndex: "doMerge",
      key: "mergeStatus",
      render: (_: any, record: DataType) => {
        return record.doMerge ? "是" : "否";
      }
    },
    {
      title: "内部拼单",
      dataIndex: "doInternalMerge",
      key: "doInternalMerge",
      render: (_: any, record: DataType) => {
        return record.doInternalMerge ? "是" : "";
      }
    },
    {
      title: "外库/其他提单",
      dataIndex: "warehouseTag",
      key: "warehouseTag",
      render: (_: any, record: DataType) => {
        return record.warehouseTag ? "是" : "";
      }
    },
    { title: "外库地点", dataIndex: "warehouse", key: "warehouse" },
    { title: "预计提货日期", dataIndex: "estimatedDeliveryDate", key: "expectedDeliveryDate" },
    {
      title: "系统核查失败原因",
      dataIndex: "creditCheckFailReason",
      key: "creditCheckFailReason",
      render: (_: any, record: any) => {
        return (
          <div title={record.creditCheckFailReason} className="ellipsis">
            {record.creditCheckFailReason || ""}
          </div>
        );
      }
    },
    { title: "操作人", dataIndex: "operator", key: "operator" },
    { title: "客户提货单号", dataIndex: "entrustNo", key: "entrustNo" },
    {
      title: "配送订单号",
      dataIndex: "distributionNo",
      key: "distributionNo",
      render: (_: any, record: any) => {
        return isEnabledAction(record, "distributionNo") &&
          ["ACM配送车", "VA配送车", "ACM外江码头"].indexOf(record.dscTransportType) !== -1 ? (
          <EditColumn
            data={record}
            name="distributionNo"
            setLoadingStatus={props.setLoadingStatus}
            width={80}
            initValue={record.distributionNo ? record.distributionNo : "-"}
            getTableData={getTable}
          />
        ) : (
          <span>{record.distributionNo || "-"}</span>
        );
      }
    },
    { title: "拼单编号", dataIndex: "mergeRemark", key: "mergeRemark" },
    {
      title: "当前合同余量",
      dataIndex: "contractRemainingQty",
      key: "contractRemainingQty",
      render: (_: any, record: DataType) => {
        return record.contractRemainingQty ? record.contractRemainingQty : "-";
      }
    },
    {
      title: "预计到货日期",
      dataIndex: "arrivalDate",
      key: "arrivalDate",
      render: (_: any, record: DataType) => {
        return record.arrivalDate ? record.arrivalDate : "-";
      }
    },
    {
      title: "物流状态",
      dataIndex: "deliveryStatus",
      key: "deliveryStatus",
      render: (_: any, record: any) => {
        return record.deliveryStatus || "-";
      }
    },
    {
      title: "限量审核状态",
      dataIndex: "limitStatus",
      key: "limitStatus"
    },
    {
      title: "自动审单",
      dataIndex: "autoExamine",
      key: "autoExamine",
      render: (_: any, record: any) => {
        return record.autoExamine ? "是" : "否"
      }
    },
    {
      title: "延迟审单时间",
      dataIndex: "delayExamineDate",
      key: "delayExamineDate",
      render: (_: any, record: any) => {
        return record.delayExamineDate ? moment(record.delayExamineDate).format("YYYY-MM-DD HH:mm") : ""
      }
    },
    {
      title: "占量锁定状态",
      dataIndex: "occStatus",
      key: "occStatus"
    },
    {
      title: "跨客户拼单",
      dataIndex: "customerMerge",
      key: "customerMerge",
      render: (_: any, record: any) => {
        return record.customerMerge ? "是" : "否"
      }
    },
    {
      title: "非自动审单原因",
      dataIndex: "personalReason",
      key: "personalReason"
    }
  ];
  const {
    data,
    loading,
    setLoadingStatus,
    downloadFileName,
    downloadType,
    TableButtonConfig,
    isFulfillmentOrderPage,
    isPendingPage,
    totalCount,
    fieldsDesc,
    allFields,
    getColumns,
    getMetadata,
    getTable,
    searchValue,
    current,
    keys,
    selectedRows,
    setSelectedRows,
    selectedRowKeys,
    setSelectedRowKeys,
    isRejectedPage,
    isPendingEditPage
  } = props;

  const [myColumns, setMyColumns] = useState([]);
  const [show, setShow] = useState({ key: -1, visible: false });
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [isAbleEditQty, setIsAbleEditQty] = useState(false);

  // 分页
  const onPaginationChange = useCallback(
    (page: number, pageSize: any) => {
      setLimit(pageSize);
      setOffset((page - 1) * pageSize);
      getMetadata({
        offset: pageSize === limit ? (page - 1) * pageSize : 0,
        limit: pageSize,
        current: pageSize === limit ? page : 1
      });
    },
    [getMetadata, limit]
  );

  // 展示隐藏 Popconfirm
  const handleChange = useCallback(
    (key: any) => (eventValue?: boolean) => {
      if (eventValue) {
        setShow({ key, visible: eventValue });
      } else {
        setShow({ key: -1, visible: false });
      }
    },
    []
  );

  // kaClient === "TW"为通威提货单号，不可编辑
  const isTwDelivery = (item: any) => {
    if (item.kaClient === "TW" || item.kaClient === "WS") {
      return false;
    }
    return true;
  };

  const allActionCheckConditions = (item: any) => {
    // Tab-全部申请 ｜ 已拒绝
    // 温氏配送提单(DELIVERYBASISDELIVERY)被拒绝时允许重新提交,温氏自提单原逻辑不变
    const disabledRejectReason = ["调用 SAP 接口失败"]; // 不可再提交原因
    const kaClientArr = ["WS"];
    const rejectedCommonConditions =
      !item.salesOrderNumber &&
      item.doStatus === "已拒绝" &&
      kaClientArr.includes(item.kaClient) &&
      !disabledRejectReason.includes(item.rejectReason);
    // 已拒绝 && 提交时间没有超过48小时 && WS && 指定的运输类型 && 不在不可再提交原因中-接口失败
    const dscTransportTypeEnum = [
      "ACM配送车",
      "VA配送车",
      "ACM外江码头",
      "ACM外库自提车",
      "ACM外库配送船",
      "VA外库自提车",
      "VA外库配送车"
    ];
    if (
      rejectedCommonConditions &&
      !compareDate(item.submitDate, item.currentTime, 48) &&
      dscTransportTypeEnum.includes(item.dscTransportType)
    ) {
      return true;
    }

    // Tab-全部申请其他情况
    const excludesDoStatus = ["已拒绝", "已取消", "系统审核中"];
    if (keys === "allDo" && !excludesDoStatus.includes(item.doStatus) && !item.salesOrderNumber) {
      return true;
    }

    // 默认不放开，最小权限原则
    return false;
  };

  const pendingPageCheck = (item: any, key: string) => {
    // 行既不可选择，字段也不可编辑: key !== "distributionNo" && item.doStatus !== "信用审核失败";
    // 针对字段 "distributionNo"(配送订单号) 不是“系统审核中”才可编辑: key === "distributionNo" && item.doStatus !== "系统审核中";
    return key === "distributionNo" ? item.doStatus !== "系统审核中" : item.doStatus !== "信用审核失败";
  };

  // 根据每条数据给出的判定，不依赖哪个Tab下，只根据当前数据的doStatus状态进行相应的判断
  const eachItemCommonConditions = (itemData: any) => {
    const disabledRejectReason = ["调用 SAP 接口失败"]; // 可再提交原因
    return itemData?.doStatus === "已拒绝" && disabledRejectReason.includes(itemData?.rejectReason);
  };

  // Action是否激活的细节逻辑--最小权限原则
  // key存在时，针对可编辑的字段进行判断，无值时针对列表是否可以选中进行判断
  const isEnabledAction = (item: any, key: string = "") => {
    // 订单为已录入配送信息，且此时订单状态为“受理中”才可以重复编辑配送信息，其它状态均不可编辑; 或者未录入时可编辑(isPendingPage | isFulfillmentOrderPage)
    const commonCondition1 = (item.haveEntry === "已录入" && item.doStatus === "受理中") || item.haveEntry !== "已录入";
    // 所有Tab的是否可选的共同条件
    const excludesDoStatus = ["已取消", "系统审核中"];
    const commonCondition2 = !excludesDoStatus.includes(item.doStatus) && !item.salesOrderNumber;
    const commonStatus = commonCondition1 && commonCondition2;

    // 全局条件匹配--目前判断false就直接返回，否则进入原来的判断逻辑--待最后统一优化
    if (eachItemCommonConditions(item) && !key) {
      return true;
    }

    // Tab-配送待编辑
    if (isFulfillmentOrderPage) {
      return key === "cycleId" || key === "nominationNumber" ? item.doStatus !== "信用审核失败" : commonStatus;
    }

    // Tab-自提待编辑
    if (isPendingEditPage) {
      // 可编辑都可选
      const myCondition1 = key !== "distributionNo";
      return key === "distributionNo" ? commonStatus : myCondition1;
    }

    // Tab-待审批页面
    if (isPendingPage) {
      return pendingPageCheck(item, key);
    }

    // 其他 Tab- 全部申请 和 已拒绝
    return allActionCheckConditions(item);
  };

  const rowSelection = {
    type: "checkbox",
    fixed: true,
    selectedRowKeys,
    getCheckboxProps: (item: any) => {
      return { disabled: !isEnabledAction(item) };
    },
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      setSelectedRows(selectedRows);
      setSelectedRowKeys(selectedRowKeys);
    }
  };

  useEffect(() => {
    const { userDetails } = props;
    // 只针对 CM/CE 用户才能进行doQuantity或doQuantityByPackage的编辑
    if (userDetails && userDetails.groups && userDetails.groups.length > 0) {
      if (
        userDetails.groups.indexOf("DXP_DSC_Role_CN_Cargill_CM") > -1 ||
        userDetails.groups.indexOf("DXP_DSC_Role_CN_Cargill_CE") > -1
      ) {
        setIsAbleEditQty(true);
      }
    }
  }, [props]);

  useEffect(() => {
    let newColumns = fieldsDesc.map((item: any) => {
      return allColumns.find((val: any) => {
        return val.key === item.value;
      });
    }).filter(Boolean);
    if (isPendingEditPage || isPendingPage) {
      newColumns = newColumns.filter((val: any) => {
        return val.key !== "rejectReason" && val.key !== "overrideStatus";
      });
    }
    // 只有待审批页面才显示物流状态字段：deliveryStatus
    if (!isPendingPage) {
      newColumns = newColumns.filter((val: any) => {
        return val.key !== "deliveryStatus";
      });
    }
    if (!isHaveThisPermission("CNInternalUsers")) {
      newColumns = newColumns.filter((val: any) => {
        return val.key !== "doInternalMerge";
      });
    }
    newColumns.push({
      title: "操作",
      dataIndex: "option",
      key: "option",
      width: isMobileDevice() ? 40 : 60,
      align: "center" as "center",
      fixed: "right" as "right",
      render: (_: any, record: any) => {
        return (
          <Popconfirm
            overlayClassName="apply-popover"
            title={
              <div className="table-option">
                <span
                  onClick={() => {
                    setShow({ key: -1, visible: false });
                  }}
                >
                  {TableButtonConfig.isConfirmSubmitButton && (
                    <ConfirmSubmit
                      text={true}
                      data={[record]}
                      keys={keys}
                      getTable={getTable}
                      setLoadingStatus={setLoadingStatus}
                    >
                      确认提交
                    </ConfirmSubmit>
                  )}
                  {TableButtonConfig.isApprovedButton && (
                    <ConfirmSubmit
                      text={true}
                      data={[record]}
                      keys={keys}
                      getTable={getTable}
                      setLoadingStatus={setLoadingStatus}
                    >
                      审批通过
                    </ConfirmSubmit>
                  )}
                </span>
                {TableButtonConfig.isRefuseButton && (
                  <span
                    onClick={() => {
                      setShow({ key: -1, visible: false });
                    }}
                  >
                    <RefuseButton text={true} data={[record]} getTable={getTable} keys={keys} />
                  </span>
                )}
                <span
                  onClick={() => {
                    setShow({ key: -1, visible: false });
                  }}
                >
                  <EditText
                    datas={{ ...record }}
                    getTable={getTable}
                    isFulfillmentOrderPage={isFulfillmentOrderPage as boolean}
                    isRejectedPage={isRejectedPage as boolean}
                    isPendingEditPage={isPendingEditPage as boolean}
                    isPendingPage={isPendingPage as boolean}
                    isAbleEditQty={isAbleEditQty}
                  />
                </span>
                {(record.doStatus !== "待取消" && record.occStatus !== "已锁定") &&
                  <span
                    onClick={() => {
                      setShow({ key: -1, visible: false });
                    }}
                  >
                    <ForceLock text={true} data={[record]} getTable={getTable} />
                  </span>
                }
              </div>
            }
            icon={false}
            placement="left"
            trigger="click"
            visible={show.key === record.doId && show.visible}
            onVisibleChange={handleChange(record.doId)}
            disabled={!isEnabledAction(record)}
          >
            <MoreOutlined
              style={{
                color: isEnabledAction(record) ? "#007681" : "#ccc",
                cursor: isEnabledAction(record) ? "pointer" : "not-allowed"
              }}
            />
          </Popconfirm>
        );
      }
    });

    setMyColumns([...newColumns] as any);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldsDesc, getTable, show.key, show.visible]);

  return (
    <div className="tab-content">
      <Spin spinning={loading}>
        <Card
          style={{ boxShadow: "0 5px 9px #dedbdb", marginTop: 20 }}
          bodyStyle={{ paddingTop: 0 }}
          title={
            <Row gutter={[16, 16]}>
              <Col span={4}>
                <span>提货委托函列表</span>
              </Col>
              {isMobileDevice() ? (
                <Col style={{ marginLeft: "auto" }}>
                  <Row gutter={[16, 16]}>
                    <Col>
                      <span style={{ fontSize: 12 }}>共 {totalCount} 条</span>
                    </Col>
                    <Col>
                      <MobileTableOperation isRotate={true}>
                        {TableButtonConfig.isSpecialApproval && (
                          <Button type="text" disabled={true}>
                            特批
                          </Button>
                        )}
                        {TableButtonConfig.isBatchEditButton && (
                          <BatchEdit
                            text={true}
                            data={selectedRows}
                            getTable={getTable}
                            isPendingEditPage={isPendingEditPage}
                            isFulfillmentOrderPage={isFulfillmentOrderPage}
                            isPendingPage={isPendingPage}
                          />
                        )}
                        {TableButtonConfig.isRefuseButton && (
                          <RefuseButton text={true} data={selectedRows} getTable={getTable} keys={keys} />
                        )}
                        {TableButtonConfig.isConfirmSubmitButton && (
                          <ConfirmSubmit
                            text={true}
                            data={selectedRows}
                            getTable={getTable}
                            keys={keys}
                            setLoadingStatus={setLoadingStatus}
                          >
                            确认提交
                          </ConfirmSubmit>
                        )}
                        {TableButtonConfig.isApprovedButton && (
                          <ConfirmSubmit
                            text={true}
                            data={selectedRows}
                            getTable={getTable}
                            keys={keys}
                            setLoadingStatus={setLoadingStatus}
                          >
                            审批通过
                          </ConfirmSubmit>
                        )}
                        {TableButtonConfig.isCombineButton && (
                          <CombineButton text={true} data={selectedRows} getTable={getTable} isDeliveryLetterList />
                        )}
                        {TableButtonConfig.isCancelCombineButton && (
                          <CancelCombine text={true} data={selectedRows} getTable={getTable} isDeliveryLetterList />
                        )}
                        {TableButtonConfig.isDownloadButton && (
                          <DownloadReport
                            text={true}
                            data={{}}
                            params={{
                              limit: limit,
                              type: downloadType,
                              offset: offset,
                              total: totalCount,
                              ...searchValue
                            }}
                            filename={downloadFileName}
                            fileType={type.DELIVERYLETTERLIST_TYPE}
                          />
                        )}
                      </MobileTableOperation>
                    </Col>
                  </Row>
                </Col>
              ) : (
                <Col style={{ marginLeft: "auto" }}>
                  <Row gutter={[8, 8]}>
                    <Col>
                      <span style={{ fontSize: 12 }}>共 {totalCount} 条</span>
                    </Col>
                    {TableButtonConfig.isSpecialApproval && (
                      <Col>
                        <Button disabled={true}>特批</Button>
                      </Col>
                    )}
                    {TableButtonConfig.isBatchEditButton && (
                      <Col>
                        <BatchEdit
                          data={selectedRows}
                          getTable={getTable}
                          isPendingEditPage={isPendingEditPage}
                          isFulfillmentOrderPage={isFulfillmentOrderPage}
                          isPendingPage={isPendingPage}
                        />
                      </Col>
                    )}
                    {TableButtonConfig.isRefuseButton && (
                      <Col>
                        <RefuseButton data={selectedRows} getTable={getTable} keys={keys} />
                      </Col>
                    )}
                    {TableButtonConfig.isConfirmSubmitButton && (
                      <Col>
                        <ConfirmSubmit
                          data={selectedRows}
                          getTable={getTable}
                          keys={keys}
                          setLoadingStatus={setLoadingStatus}
                        >
                          确认提交
                        </ConfirmSubmit>
                      </Col>
                    )}
                    {TableButtonConfig.isApprovedButton && (
                      <Col>
                        <ConfirmSubmit
                          data={selectedRows}
                          getTable={getTable}
                          keys={keys}
                          setLoadingStatus={setLoadingStatus}
                        >
                          审批通过
                        </ConfirmSubmit>
                      </Col>
                    )}
                    {TableButtonConfig.isCombineButton && (
                      <Col>
                        <CombineButton data={selectedRows} getTable={getTable} isDeliveryLetterList />
                      </Col>
                    )}
                    {TableButtonConfig.isCancelCombineButton && (
                      <Col>
                        <CancelCombine data={selectedRows} getTable={getTable} isDeliveryLetterList />
                      </Col>
                    )}
                    {TableButtonConfig.isDownloadButton && (
                      <Col>
                        <DownloadReport
                          data={{}}
                          params={{
                            limit: limit,
                            type: downloadType,
                            offset: offset,
                            total: totalCount,
                            ...searchValue
                          }}
                          cancelMaxLimit={true}
                          filename={downloadFileName}
                          fileType={type.DELIVERYLETTERLIST_TYPE}
                        />
                      </Col>
                    )}
                    <Col>
                      <FieldSetting fieldDesc={fieldsDesc} allFields={allFields} getColumns={getColumns} />
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          }
          headStyle={{
            backgroundColor: "white",
            fontWeight: 100,
            borderBottom: "none"
          }}
        >
          <Table
            size="small"
            rowSelection={rowSelection as any}
            columns={[...myColumns]}
            rowKey="doId"
            dataSource={[...data]}
            scroll={{ x: "max-content" }}
            pagination={false}
          />
          <Pagination
            current={current}
            style={{ marginTop: 20, width: "100%", textAlign: "right" }}
            total={totalCount}
            size={isMobileDevice() ? "small" : "default"}
            showSizeChanger
            showQuickJumper
            onChange={onPaginationChange}
          />
        </Card>
      </Spin>
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  userDetails: state.userDetails
});

export default connect(mapStateToProps, null)(TabTable);
