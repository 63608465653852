import NotificationTips from "@/pages/common/notificationTips";
import { Card, message, PageHeader } from "antd";
import SearchFilter from "./components/searchFilters";
import TableList, { TableListHandle } from "./components/tableList";

import "./index.scss";
import { deviceWidth } from "@/utils";
import { useCallback, useRef, useState } from "react";
import ConfigurationDetails from "./components/configurationDetails";
import { autoExamineQuery, autoExamineUpdate } from "@/api/systemSetting";

const pagination = {
  current: 1,
  total: 0,
  showSizeChanger: true,
  showQuickJumper: true,
  size: deviceWidth() > 576 ? ("default" as any) : ("small" as any),
}

const AutoExamine: React.FC = (props: any) => {
  const tableListRef = useRef<TableListHandle>(null);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);
  const [totalPageNumber, setTotalPageNUmber] = useState<number>(0);
  const [searchValues, setSearchValues] = useState<any>(null);

  const cardExtra = useCallback(() => {
    return (
      <div className="hdp-uf hdp-uf-vc hdp-uf-ww card-extra">
        <div className="extra-total extra-item">
          <span>共 </span>
          <span>{totalPageNumber}</span>
          <span> 条</span>
        </div>
      </div>
    );
  }, [totalPageNumber]);

  // table翻页回调函数
  const onPaginationChange = (page: number) => {
    setCurrentPageNumber(page);
  };

  const getAutoExamine = useCallback((params: any, isReSelect?: boolean) => {
    setSearchLoading(true);
    autoExamineQuery({
      orgCode: params.orgCode,
      businessLine: params.businessLine
    })
      .then((res: any) => {
        if (res?.data && Object.keys(res.data).length) {
          const tableData = Array.isArray(res.data) ? res.data : [];
          setTableData(tableData);
          setTotalPageNUmber(tableData.length);
          console.log("isReSelect=", isReSelect);
          console.log("selectedRows=", selectedRows);
          if (isReSelect) {
            const selectedRowsTemp = tableData.find((item: any) => item.id === selectedRows[0].id)
            if (selectedRowsTemp) {
              tableListRef.current?.selectRowKeys([selectedRowsTemp.id]);
            }
          }
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
        message.error("获取数据失败，请稍后重试。");
        setTableData([]);
        setCurrentPageNumber(1);
        setTotalPageNUmber(0);
      })
      .finally(() => {
        setSearchLoading(false);
      })
  }, [selectedRows]);

  /**
   * 监听点击查询
   */
  const handleSearch = useCallback(
    (values: any) => {
      setSearchValues(values);
      getAutoExamine(values);
      setCurrentPageNumber(1);
      setTotalPageNUmber(0);
      setSelectedRows([]);
      tableListRef.current?.selectRowKeys([]);
    },
    [tableListRef, getAutoExamine]
  );

  const onRowSelect = useCallback((selectedRowsKeys: any[], selectedRows: any[]) => {
    console.log("selectedRows=", selectedRows);
    setSelectedRows(selectedRows);
  }, []);

  /**
   * 监听自动审单配置详情保存
   */
  const onFinish = useCallback((values: any) => {
    setSearchLoading(true);
    autoExamineUpdate(values)
      .then((res: any) => {
        message.success("保存成功");
        getAutoExamine(searchValues, true);
      })
      .catch((e: any) => {
        message.error("保存失败");
      })
      .finally(() => {
        setSearchLoading(false);
      })
  }, [getAutoExamine, searchValues]);

  return (
    <div className="auto-approve">
      <PageHeader className="page-header" title="自动审单设置">
        <NotificationTips />
      </PageHeader>
      <div className="auto-approve-content">
        <Card
          style={{ boxShadow: "0 5px 9px #dedbdb" }}
          bodyStyle={{ paddingTop: 0 }}
          title="查询条件"
          headStyle={{
            backgroundColor: "white",
            fontWeight: 100,
            borderBottom: "none"
          }}
        >
          <SearchFilter onSearch={handleSearch} />
        </Card>
        <Card
          style={{ boxShadow: "0 5px 9px #dedbdb", marginTop: 20 }}
          bodyStyle={{ paddingTop: 0 }}
          headStyle={{
            backgroundColor: "white",
            fontWeight: 100,
            borderBottom: "none"
          }}
          title="自动审单配置列表"
          extra={cardExtra()}
        >
          <TableList
            ref={tableListRef}
            tableData={tableData}
            onRowSelect={onRowSelect}
            pagination={{
              ...pagination,
              current: currentPageNumber,
              total: totalPageNumber,
              onChange: onPaginationChange
            }}
            loading={searchLoading}
          />
        </Card>
        <Card
          style={{ boxShadow: "0 5px 9px #dedbdb", marginTop: 20 }}
          bodyStyle={{ paddingTop: 0 }}
          title="自动审单配置详情"
          headStyle={{
            backgroundColor: "white",
            fontWeight: 100,
            borderBottom: "none"
          }}
        >
          <ConfigurationDetails dataSource={selectedRows[0] || {}} onFinish={onFinish} />
        </Card>
      </div>
    </div >
  );
}

export default AutoExamine;