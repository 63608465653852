import { SearchOutlined } from "@ant-design/icons";
import { Button, Card, Col, Collapse, Select, Divider, Form, Input, Row, DatePicker, message } from "antd";
import { useCallback, useEffect, useState } from "react";
import {
  allBusinessLineMutiple,
  doStatus,
  isCombineOptions,
  pickUpMethodOptionsEn,
  shippingInfoOption
} from "@/utils/constant";
import { connect } from "react-redux";
import "./index.scss";
import MyAutoComplete from "@/pages/common/autoComplete";
import { getProductDetailList, getSapFirmDetailsList } from "@/api/contractReport";
import { getCode } from "@/utils/common";
import { getFilterData } from "@/utils/formatUtils";
import moment from "moment";

const { Panel } = Collapse;
const { RangePicker } = DatePicker;

interface Props {
  isApplyStatus?: boolean; // 提单申请状态选择框
  isDeliveryDate?: boolean; // 提货单提交日期选择框
  isFulfillment?: boolean; // 是否是配送订单
  isPending?: boolean; // 是否是待审批
  isPendingEdit?: boolean;
  isReject?: boolean;
  getSearchValue: any;
  switcher: any;
}

const TabFilter = (props: Props) => {
  const {
    isApplyStatus,
    isDeliveryDate,
    isFulfillment,
    isPending,
    getSearchValue,
    switcher,
    isPendingEdit,
    isReject
  } = props;

  const [form] = Form.useForm();
  const [isClick, setIsClick] = useState(false);
  const [isExpand, setIsExpand] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [productNameOptions, setProductNameOptions] = useState([]);
  const [buyerOptions, setBuyerOptions] = useState([]);
  const [divisionCodeOptions, setDivisionCodeOptions] = useState([]);
  const [expandCollapseText, setExpandCollapseText] = useState("展开高级筛选");
  const [searchValue, setSearchValue] = useState({} as any);

  // 抽取赋值方法
  const setValue = (switcher: any) => {
    const { orgCode, divisionCode } = switcher;
    let value: any = searchValue;
    if (!searchValue.deliveryType) {
      value.deliveryType = "All";
      if (isFulfillment) {
        value.deliveryType = "DELIVERY";
      } else if (isPendingEdit) {
        value.deliveryType = "ZTNULL";
      }
    }
    if (!isReject) {
      value.isNew = 1;
    }
    if (!searchValue.haveEntry) {
      value.haveEntry = isPendingEdit || isPending ? "true" : "All";
    }
    value.orgCode = orgCode || "";
    value.divisionCode = divisionCode || "";
    form.setFieldsValue({
      orgCode: value.orgCode,
      divisionCode: value.divisionCode && value.divisionCode !== "All" ? value.divisionCode.split(",") : []
    });
    setSearchValue(value);
    getSearchValue(value);
  };
  useEffect(() => {
    if (switcher) {
      setValue(switcher);
      setBuyerOptions([]);
      setProductNameOptions([]);
      setDivisionCodeOptions(allBusinessLineMutiple() as any);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, switcher.orgCode, switcher.divisionCode]);

  const handelExpandCollapse = useCallback(() => {
    setIsClick(!isClick);
    setIsExpand(!isExpand);
    setExpandCollapseText(isExpand ? "展开高级筛选" : "收起高级筛选");
  }, [isClick, isExpand]);

  // 获取产品名称选项
  const getProductNameOptions = useCallback(() => {
    setIsSearchLoading(true);
    setProductNameOptions([]);
    getProductDetailList({
      salesOrgs: searchValue.orgCode,
      divisionCode: searchValue.divisionCode,
      productName: searchValue.productCode
    })
      .then((res: any) => {
        if (res.code && res.code === "99999") {
          // 99999 提示后端的错误信息
          message.error(res.errorMsg);
        } else {
          if (res.data.products) {
            if (res.data.products.length === 0) {
              message.error("No Results Found");
            }
            let products: any = [];
            products = res.data.products.map((item: any) => {
              return {
                label: `${item.productName}(${item.productCode.replace(/\b(0+)/gi, "")})`,
                value: `${item.productName}(${item.productCode.replace(/\b(0+)/gi, "")})`,
                key: item.productCode.replace(/\b(0+)/gi, "")
              };
            });
            setProductNameOptions(products);
          }
        }
      })
      .catch(() => {
        setProductNameOptions([]);
      })
      .finally(() => {
        setIsSearchLoading(false);
      });
  }, [searchValue.divisionCode, searchValue.orgCode, searchValue.productCode]);

  // 获取买方选项
  const getBuyerOptions = useCallback(() => {
    setIsSearchLoading(true);
    setBuyerOptions([]);
    getSapFirmDetailsList({
      customerName: searchValue.customerCode,
      orgCode: searchValue.orgCode,
      divisionCode: searchValue.divisionCode
    })
      .then((res: any) => {
        if (res.code && res.code === "99999") {
          // 99999 提示后端的错误信息
          message.error(res.errorMsg);
        } else {
          if (res.data.customerList?.length) {
            let data = res.data.customerList.map((val: any) => {
              return {
                label: val.customerName + "(" + val.customerCode + ")",
                value: val.customerName + "(" + val.customerCode + ")",
                key: val.customerCode
              };
            });
            data = getFilterData(data, searchValue.customerCode);
            setBuyerOptions(data);
          } else {
            message.error("No Results Found");
          }
        }
      })
      .catch(() => {
        setBuyerOptions([]);
      })
      .finally(() => {
        setIsSearchLoading(false);
      });
  }, [searchValue.customerCode, searchValue.divisionCode, searchValue.orgCode]);

  const updateDivisionCodeOptions = useCallback((value: any) => {
    const selectValue = value;
    const acmDivison = ["40", "38"];
    const vaDivison = ["39", "43"];
    if (selectValue?.length > 0) {
      const currentDivisionCodeOptions: any = divisionCodeOptions.map((item: any) => acmDivison.includes(value[0]) ?
        { ...item, disabled: !acmDivison.includes(item.value) } :
        { ...item, disabled: !vaDivison.includes(item.value) });
      setDivisionCodeOptions(currentDivisionCodeOptions);
    } else {
      setDivisionCodeOptions(allBusinessLineMutiple() as any);
    }
  }, [divisionCodeOptions]);

  // 查询
  const onFinish = useCallback(
    (values: any) => {
      values.customerCode = getCode(values.customerCode);
      values.productCode = getCode(values.productCode);
      if (values.createdDateRange && values.createdDateRange.length > 0) {
        values.createdDateStart = moment(values.createdDateRange[0]).format("YYYY-MM-DD");
        values.createdDateEnd = moment(values.createdDateRange[1]).format("YYYY-MM-DD");
      }
      // 删除不需要传递的字段
      delete values.createdDateRange;

      values.orgCode = switcher.orgCode === null ? "" : switcher.orgCode;
      if (values?.divisionCode && Array.isArray(values?.divisionCode) && values?.divisionCode.length) {
        values.divisionCode = values.divisionCode.join(",");
      }
      if (!isReject) {
        values.isNew = 1;
      }
      setSearchValue(values);
      getSearchValue(values);
    },
    [getSearchValue, isReject, switcher.orgCode]
  );

  const onChange = useCallback(
    (changeValue: any, allValues: any) => {
      if (!isFulfillment) {
        if (changeValue.deliveryType === "ZTNULL") {
          allValues.haveEntry = "true";
          form.setFieldsValue({ haveEntry: "true" });
          setDisabled(true);
        } else {
          setDisabled(false);
        }
      }
      allValues.orgCode = switcher.orgCode === null ? "" : switcher.orgCode;
      allValues.divisionCode = allValues.divisionCode ? allValues.divisionCode.join(",") : "";
      setSearchValue(allValues);
    },
    [form, isFulfillment, switcher.orgCode]
  );

  // 清空
  const resetValue = useCallback(() => {
    let value: any = { deliveryType: "", haveEntry: "", divisionCode: "", orgCode: "" };
    value.deliveryType = "All";
    value.haveEntry = isPendingEdit || isPending ? "true" : "All";
    value.divisionCode = switcher.divisionCode === null ? "" : switcher.divisionCode;
    value.orgCode = switcher.orgCode === null ? "" : switcher.orgCode;
    if (!isReject) {
      value.isNew = 1;
    }
    if (value.deliveryType) {
      if (isFulfillment) {
        value.deliveryType = "DELIVERY";
      } else if (isPendingEdit) {
        value.deliveryType = "ZTNULL";
      }
    }
    const selectDivisonCodeValue = switcher.divisionCode && switcher.divisionCode !== "All" ? switcher.divisionCode.split(",") : [];
    updateDivisionCodeOptions(selectDivisonCodeValue);
    form.resetFields();
    form.setFieldsValue({
      divisionCode: switcher.divisionCode && switcher.divisionCode !== "All" ? switcher.divisionCode.split(",") : []
    });
    setBuyerOptions([]);
    setProductNameOptions([]);
    setSearchValue(value);
    getSearchValue(value);
  }, [
    form,
    getSearchValue,
    isFulfillment,
    isPending,
    isPendingEdit,
    isReject,
    switcher.divisionCode,
    switcher.orgCode,
    updateDivisionCodeOptions
  ]);

  const onGetDeliveryTypeInitVal = useCallback(() => {
    if (isFulfillment) {
      return "DELIVERY";
    } else {
      return isPendingEdit ? "ZTNULL" : "All";
    }
  }, [isFulfillment, isPendingEdit]);

  // 跨Business Divison (ACM及VA)不允许多选
  const onDivisionCodeChange = useCallback((value: any) => {
    updateDivisionCodeOptions(value);
  }, [updateDivisionCodeOptions]);

  return (
    <div className="all-apply">
      <Card
        style={{ boxShadow: "0 5px 9px #dedbdb" }}
        bodyStyle={{ paddingTop: 0 }}
        title="查询条件"
        headStyle={{
          backgroundColor: "white",
          fontWeight: 100,
          borderBottom: "none"
        }}
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          onValuesChange={onChange}
          initialValues={{
            divisionCode: [],
            haveEntry: isPendingEdit || isPending ? "true" : "All",
            deliveryType: onGetDeliveryTypeInitVal(),
            doStatus: "",
            doMerge: "",
            createdDateRange: []
          }}
        >
          <Row className="flex-row-class" gutter={{ xs: 8, md: 16 }}>
            <Col xs={24}>
              <Form.Item name="divisionCode">
                <Select
                  style={{ width: "100%" }}
                  placeholder="请选择业务线"
                  mode="multiple"
                  maxTagCount={"responsive" as const}
                  options={divisionCodeOptions}
                  onChange={onDivisionCodeChange}
                  data-testid="divisionCode"
                />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item name="productCode">
                <MyAutoComplete
                  onFocus={getProductNameOptions}
                  options={productNameOptions}
                  placeholder="请输入产品名称"
                  isLoading={isSearchLoading}
                  type="sap"
                  value={searchValue.productCode}
                />
              </Form.Item>
            </Col>
            {isApplyStatus && (
              <Col xs={24}>
                <Form.Item name="doStatus">
                  <Select placeholder="请选择提单申请状态" options={doStatus} />
                </Form.Item>
              </Col>
            )}
            <Col xs={24}>
              <Form.Item name="truckNumber">
                <Input placeholder="请输入车船号" />
              </Form.Item>
            </Col>
            {!isDeliveryDate && (
              <Col xs={24}>
                <Form.Item name="createdDateRange">
                  <RangePicker placeholder={["提单提交开始日期", "提单提交结束日期"]} />
                </Form.Item>
              </Col>
            )}
            <Col className="flex-row-class-btn" xs={24}>
              <Form.Item>
                <Button
                  data-testid="submit"
                  style={{ width: "100%" }}
                  type="primary"
                  className="base-submit-btn"
                  htmlType="submit"
                  icon={<SearchOutlined />}
                  disabled={isClick}
                >
                  查询
                </Button>
              </Form.Item>
            </Col>
          </Row>
          <Collapse
            style={{ marginTop: -25 }}
            ghost
            expandIconPosition="right"
            onChange={handelExpandCollapse}
            collapsible="header"
          >
            <Panel header={<span style={{ color: "#007ed9" }}>{expandCollapseText}</span>} key="1" forceRender={true}>
              <Divider dashed={true} style={{ width: "101%", margin: "-10px 0 13px -14px" }} />
              <Row gutter={{ xs: 8, md: 16 }}>
                {isDeliveryDate && (
                  <Col md={5} xs={24}>
                    <Form.Item label="提单创建开始日期和结束日期" name="createdDateRange">
                      <RangePicker style={{ width: "100%" }} placeholder={["开始日期", "结束日期"]} />
                    </Form.Item>
                  </Col>
                )}
                <Col md={3} xs={24}>
                  <Form.Item label="是否拼单" name="doMerge">
                    <Select placeholder="请选择" options={isCombineOptions} />
                  </Form.Item>
                </Col>
                <Col md={3} xs={24}>
                  <Form.Item label="提货方式" name="deliveryType">
                    <Select
                      placeholder="请选择"
                      disabled={isFulfillment || isPendingEdit}
                      options={pickUpMethodOptionsEn}
                      data-testid="deliveryType"
                    />
                  </Form.Item>
                </Col>
                <Col md={4} xs={24}>
                  <Form.Item label="是否录入配送信息" name="haveEntry">
                    <Select
                      placeholder="请选择"
                      disabled={isPendingEdit || (isReject && disabled)}
                      options={shippingInfoOption}
                    />
                  </Form.Item>
                </Col>
                <Col md={4} xs={24}>
                  <Form.Item label="买方" name="customerCode">
                    <MyAutoComplete
                      onFocus={getBuyerOptions}
                      options={buyerOptions}
                      isLoading={isSearchLoading}
                      placeholder="请输入买方"
                      type="sap"
                      value={searchValue.customerCode}
                    />
                  </Form.Item>
                </Col>
                <Col className="advance-btn-col">
                  <Form.Item>
                    <div className="advance-btn">
                      <Button
                        data-testid="reset-btn"
                        style={{ marginRight: 10, backgroundColor: "#5c5c5c" }}
                        onClick={resetValue}
                        type="primary"
                      >
                        清空
                      </Button>
                      <Button data-testid="submit-btn" type="primary" htmlType="submit">
                        立即查询
                      </Button>
                    </div>
                  </Form.Item>
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </Form>
      </Card>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  switcher: state.switcher
});

export default connect(mapStateToProps, null)(TabFilter);
