import { cancelMerge } from "@/api/constant";
import { Button, message, Modal } from "antd";
import { useCallback, useState } from "react";
interface Props {
  data?: any;
  style?: any;
  text?: boolean;
  getTable?: any;
  isDeliveryLetterList?: boolean;
}

const CancelCombine = (props: Props) => {
  const { style, isDeliveryLetterList, text, data } = props;
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const getDisabled = () => {
    if (isDeliveryLetterList) {
      if (data.length > 0) {
        const flag = data.find((val: any) => val.doInternalMerge === true || val.doMerge === true);
        return !flag;
      } else {
        return true;
      }
    } else {
      if (data.length > 0) {
        const flag = data.find((val: any) => val.doMerge === true);
        return !flag;
      } else {
        return true;
      }
    }
  };

  const confirmCancel = useCallback(() => {
    const temp = props.data.map((val: any) => val.doId);
    setLoading(true);
    cancelMerge({
      dscDoIds: temp,
      internalMerge: isDeliveryLetterList
    })
      .then((res: any) => {
        if (res.code && res.code === "99999") {
          // 99999 提示后端的错误信息
          message.error(res.errorMsg);
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
        message.error("撤回数据失败，请稍后重试。");
      })
      .finally(() => {
        setLoading(false);
        setVisible(false);
        props.getTable();
      });
  }, [isDeliveryLetterList, props]);

  const onHandleTrueVisible = useCallback(() => {
    setVisible(true);
  }, []);

  const onHandleCloseVisible = useCallback(() => {
    setVisible(false);
  }, []);

  return (
    <>
      <Button
        type={text ? "text" : "primary"}
        onClick={onHandleTrueVisible}
        data-testid="cancel-combine"
        disabled={getDisabled()}
        style={style}
      >
        取消拼单
      </Button>
      <Modal
        title="取消拼单"
        width={300}
        visible={visible}
        centered
        maskClosable={false}
        onCancel={onHandleCloseVisible}
        footer={
          <Button data-testid="confirm-btn" loading={loading} type="primary" onClick={confirmCancel}>
            确认
          </Button>
        }
      >
        <span>确定要取消拼单吗?</span>
      </Modal>
    </>
  );
};

export default CancelCombine;
