import { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { Card, message, PageHeader } from "antd";
import SearchFilter from "./searchFilter";
import TableList from "./TableList";
import DownloadReport from "@/pages/common/downloadReport";
import FieldSetting from "@/pages/common/fieldSetting";
import NotificationTips from "@/pages/common/notificationTips";
import * as type from "../../deliveryOrder/constant";
import "./index.scss";
import { getContracts } from "@/api/contractReport";
import { getFirmDetailsList, saveContractFieldsOrder } from "@/api/constant";
import { cutBatchNoZero } from "@/utils";
import { isHaveRolePermissionFeedFactory, isMobileDevice } from "@/utils/common";
import { connectMaterialTextAndSpec } from "@/utils/formatUtils";
import { useHistory, useLocation } from "react-router-dom";
import { useActivate } from 'react-activation';

const SapContractReport = (props: any) => {
  const [tableData, setTableData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [fieldsDesc, setFieldsDesc] = useState([]);
  const [allFields, setAllFields] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isGroupUser, setIsGroupUser] = useState(false);
  const [showBuyer, setShowBuyer] = useState(true);
  const [buyers, setBuyers] = useState([{ label: "全部买方", value: "全部买方" }]);
  const [searchValue, setSearchValue] = useState({ contractCompletionFlag: "N" });
  const [offset, setOffset] = useState(0);
  const [current, setCurrent] = useState(1);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [allCheck, setAllCheck] = useState(false);
  const [resetContent, setResetContent] = useState(false);
  const { switcher, permission, userDetail } = props;
  const history = useHistory();
  const location = useLocation();

  const doGetFirmDetailsList = useCallback(() => {
    const userDataCheck = userDetail && Object.keys(userDetail).length && userDetail.entitlements;
    if (userDataCheck) {
      const { entitlements } = userDetail;
      const {
        otherControls: { companyAccountType },
        controlIdentifier
      } = entitlements[0];
      if (companyAccountType === "GROUP") {
        getFirmDetailsList({ accountNumber: controlIdentifier })
          .then((res) => {
            if (res.data && res.data.customerInfoEntitlement?.length > 0) {
              let options = res.data.customerInfoEntitlement.map((val: any) => ({
                label: `${val.accountLongName}(${val.accountNumber})`,
                value: `${val.accountLongName}(${val.accountNumber})`,
                key: val.accountNumber,
                name: val.accountLongName
              }));
              options.unshift({
                label: "全部买方",
                value: "全部买方"
              });
              setBuyers(options);
            }
          })
          .catch((exception) => {
            console.log(`Failure: ${exception}`);
            message.error("获取数据失败，请稍后重试。");
          });
        setIsGroupUser(true);
      } else {
        setShowBuyer(false);
      }
    } else {
      setBuyers([]);
    }
  }, [userDetail]);

  const isShowDelayButton = () => {
    return (
      userDetail.groups?.includes("DXP_DSC_Role_CN_Cargill_Planner") ||
      userDetail.groups?.includes("DXP_DSC_Role_CN_Cargill_Sales")
    );
  };

  const getProductName = (value: any, data: any) => {
    const updatedTableData: any = [...tableData];
    if (!data || !value) {
      return;
    }
    const productList = data.productList.filter((product: any) => {
      let validateName = permission.CNInternalUsers
        ? product.productName
        : connectMaterialTextAndSpec(product.materialSalesText, product.packageSpecificationCn);
      if (product.businessLine === "包装油业务") {
        validateName = product.productName;
      }
      return validateName === value;
    });
    if (!productList || productList.length <= 0) {
      return;
    }
    const product = productList[0];
    const newItem = {
      ...data,
      packageSpecification: product.packageSpecification,
      packageSpecificationCn: product.packageSpecificationCn,
      packingUnit: product.packingUnit,
      packingUnitDesc: product.packingUnitDesc,
      productCode: product.productCode,
      conversionFactor: product.conversionFactor,
      productName: product.productName,
      materialSalesText: product.materialSalesText
    };
    for (let index = 0; index < updatedTableData.length; index += 1) {
      const element: any = updatedTableData[index];
      const doId1 = element.contractNo + cutBatchNoZero(element.batch);
      const doId2 = newItem.contractNo + cutBatchNoZero(newItem.batch);
      if (doId1 === doId2) {
        updatedTableData[index] = newItem;
        break;
      }
    }
    setTableData(updatedTableData);
  };

  const filterFieldsList = useCallback((dataSource: any) => {
    if (dataSource) {
      const externalUsersIgnoreFields = ["withholdingDate", "delayDate", "validDelayDate"];
      if (permission.CNExternalUsers) {
        dataSource = dataSource.filter((val: any) => !externalUsersIgnoreFields.includes(val.value));
      }
      const externalFeedFactoryUsersIgnoreFields = [
        "batchSettlementPrice",
        "settlementPriceUnitCn",
        "basisPrice",
        "basisPriceUnit"
      ];
      if (isHaveRolePermissionFeedFactory()) {
        dataSource = dataSource.filter((val: any) => !externalFeedFactoryUsersIgnoreFields.includes(val.value));
      }
    }
    return dataSource;
  }, [permission.CNExternalUsers]);

  const getTableData = useCallback((searchValue: any) => {
    setLoading(true);
    getContracts(searchValue)
      .then((res: any) => {
        if (res.code && res.code === "99999") {
          message.error(res.errorMsg);
        } else {
          const resData = res.data;
          const tableData = Array.isArray(resData.contractList) ? resData.contractList : [];
          const totalCount = resData.metadata.totalCount;
          setTotalCount(totalCount);
          setTableData(tableData);
          setFieldsDesc(filterFieldsList(JSON.parse(resData.fieldsOrder.fieldDesc)));
          setAllFields(filterFieldsList(JSON.parse(resData.fieldsOrder.allFields)));
          setSelectedRowKeys([]);
        }
      })
      .catch((exception) => {
        console.log(exception);
        if (exception?.code === "cancel") {
          console.log("Request canceled");
        } else {
          message.error("获取数据失败，请稍后重试。");
          setTableData([]);
          setTotalCount(0);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [filterFieldsList]);

  const getSearchValue = async (data: any) => {
    setCurrent(1);
    setOffset(0);
    getTableData({ offset: 0 ,...data});
    setSearchValue(data);
  };

  const getOffset = (offset: any, current: any) => {
    setOffset(offset);
    setCurrent(current);
    getTableData({ offset, ...searchValue});
  };

  const getColumns = (data: any, dataSource: any, setVisibleFun: any) => {
    saveContractFieldsOrder({
      type: "sapContractList",
      fieldDesc: JSON.stringify(data.map((item: any) => dataSource.find((val: any) => val.value === item)).filter((val: any) => val))
    })
      .catch(() => {
        message.error("字段设置失败，请稍后重试！");
      })
      .finally(() => {
        setVisibleFun(false);
        getTableData(searchValue);
      });
  };

  const onSelectAll = () => {
    setAllCheck(!allCheck);
  };

  const doGetTableInitData = () => {
    getTableData(searchValue);
  };

  const rowSelection = {
    type: "checkbox",
    fixed: true,
    selectedRowKeys,
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      setSelectedRowKeys(selectedRowKeys);
    },
    onSelectAll: onSelectAll
  };

  useActivate(() => {
    console.log('TestFunction: didActivate');
    const tempLocation: any = location;
    if (!tempLocation?.state?.referrerPage) {
      // 初始化合同筛选器及合同列表
      const tempResetContent = !resetContent;
      setTableData([]);
      setTotalCount(0);
      setResetContent(tempResetContent);
    }
    // 清除当前页面的history.state，限制如果从非详情页面跳转至当前页面则重置页面内容
    history.replace({
      pathname: "/main/contractmanagement/sapcontractreport",
      state: null
    })
  });

  useEffect(() => {
    document.title = "合同列表";
    doGetFirmDetailsList();
  }, [doGetFirmDetailsList]);

  return (
    <div className="contract-report">
      <PageHeader
        className="page-header"
        title="合同列表"
      >
        <NotificationTips />
      </PageHeader>
      <div className="contract-content">
        <Card
          style={{ boxShadow: "0 5px 9px #dedbdb" }}
          bodyStyle={{ paddingTop: 0 }}
          title="查询条件"
          headStyle={{
            backgroundColor: "white",
            fontWeight: 100,
            borderBottom: "none"
          }}
        >
          <SearchFilter
            getSearchValue={getSearchValue}
            isGroupUser={isGroupUser}
            showBuyer={showBuyer}
            buyers={buyers}
            switcher={switcher}
            permission={permission}
            userDetail={userDetail}
            resetContent={resetContent}
          />
        </Card>
        <Card
          style={{ boxShadow: "0 5px 9px #dedbdb", marginTop: 20 }}
          bodyStyle={{ paddingTop: 0 }}
          headStyle={{
            backgroundColor: "white",
            fontWeight: 100,
            borderBottom: "none"
          }}
          title={
            <div>
              <span>
                合同列表 <span className="total-mobile">( 共 {totalCount} 条 )</span>
              </span>
              <div className="report-header">
                <div className="hdp-uf hdp-uf-vc hdp-uf-ww">
                  <span className="total">共 {totalCount} 条</span>
                  <DownloadReport
                    data={{}}
                    params={{
                      limit: 10,
                      type: "01",
                      offset,
                      total: totalCount,
                      ...searchValue
                    }}
                    filename={type.CONTRACT_FILENAME}
                    fileType={type.CONTRACT_TYPE}
                  />
                  {!isMobileDevice() && (
                    <FieldSetting fieldDesc={fieldsDesc} allFields={allFields} getColumns={getColumns} />
                  )}
                </div>
              </div>
            </div>
          }
        >
          <TableList
            tableData={tableData}
            totalCount={totalCount}
            getOffset={getOffset}
            fieldsDesc={fieldsDesc}
            loading={loading}
            current={current}
            permission={permission}
            getTable={doGetTableInitData}
            rowSelection={!isShowDelayButton() && rowSelection}
            getProductName={getProductName}
          />
        </Card>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  switcher: state.switcher,
  permission: state.userPermissions,
  userDetail: state.userDetails
});

export default connect(mapStateToProps, null)(SapContractReport);